<script setup lang="ts">
import { CHIP_TYPE } from '@/types/base';
import { BOOK_STATUS } from '@/types/books';

import ChipBase from '@/components/ChipBase.vue';
import IconBase from '@/components/IconBase/IconBase.vue';

interface IBookStatus {
  type: CHIP_TYPE;
  label: string;
  icon: string;
}

const props = withDefaults(
  defineProps<{
    status: BOOK_STATUS;
    isIcon?: boolean;
  }>(),
  {
    isIcon: false,
  }
);

const { t } = useI18n();

const statusMapper: Record<BOOK_STATUS, CHIP_TYPE> = {
  [BOOK_STATUS.all]: CHIP_TYPE.white,
  [BOOK_STATUS.draft]: CHIP_TYPE.grey,
  [BOOK_STATUS.rejected]: CHIP_TYPE.grey,
  [BOOK_STATUS.under_review]: CHIP_TYPE.light_violet,
  [BOOK_STATUS.wait_list]: CHIP_TYPE.light_violet,
  [BOOK_STATUS.need_revise]: CHIP_TYPE.light_violet,
  [BOOK_STATUS.proofreading]: CHIP_TYPE.light_violet,
  [BOOK_STATUS.contract_signing]: CHIP_TYPE.light_violet,
  [BOOK_STATUS.preparing_publishing]: CHIP_TYPE.light_violet,
  [BOOK_STATUS.published]: CHIP_TYPE.green,
  [BOOK_STATUS.request_deleted]: CHIP_TYPE.red,
};

const labelMapper: Record<BOOK_STATUS, string> = {
  [BOOK_STATUS.all]: t('book.status.published'),
  [BOOK_STATUS.draft]: t('book.status.draft'),
  [BOOK_STATUS.rejected]: t('book.status.rejected'),
  [BOOK_STATUS.under_review]: t('book.status.under_review'),
  [BOOK_STATUS.wait_list]: t('book.status.wait_list'),
  [BOOK_STATUS.proofreading]: t('book.status.proofreading'),
  [BOOK_STATUS.need_revise]: t('book.status.need_revise'),
  [BOOK_STATUS.contract_signing]: t('book.status.contract_signing'),
  [BOOK_STATUS.preparing_publishing]: t('book.status.processing'),
  [BOOK_STATUS.published]: t('book.status.published'),
  [BOOK_STATUS.request_deleted]: t('book.status.deleted'),
};

const iconMapper: Record<BOOK_STATUS, string> = {
  [BOOK_STATUS.all]: 'circle-check-16',
  [BOOK_STATUS.draft]: 'pen-16',
  [BOOK_STATUS.rejected]: 'clock-16',
  [BOOK_STATUS.under_review]: 'clock-16',
  [BOOK_STATUS.wait_list]: 'clock-16',
  [BOOK_STATUS.proofreading]: 'clock-16',
  [BOOK_STATUS.need_revise]: 'clock-16',
  [BOOK_STATUS.contract_signing]: 'clock-16',
  [BOOK_STATUS.preparing_publishing]: 'clock-16',
  [BOOK_STATUS.published]: 'circle-check-16',
  [BOOK_STATUS.request_deleted]: 'clock-16',
};

const badge = computed<IBookStatus>(() => ({
  type: statusMapper[props.status],
  label: labelMapper[props.status],
  icon: iconMapper[props.status],
}));
</script>

<template>
  <chip-base
    class="chip-book-status"
    :class="{
      'chip-book-status--icon': props.isIcon,
    }"
    :type="badge.type"
    :title="badge.label"
  >
    <IconBase
      :name="badge.icon"
      size="sm"
    />

    <span
      v-if="!props.isIcon"
      class="q-ml-xs"
    >
      {{ badge.label }}
    </span>
  </chip-base>
</template>

<style lang="scss" scoped>
.chip-book-status {
  $this: 'chip-book-status';

  &--icon {
    padding-left: 4px;
    padding-right: 4px;
  }
}
</style>
