import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'novels',
    redirect: { name: 'Novels' },
    children: [
      {
        path: 'list',
        component: () => import('@/layouts/LayoutDefault.vue'),
        children: [
          {
            path: '',
            name: 'Novels',
            component: () => import('@/pages/novels/PageNovels.vue'),
            meta: {
              i18nAlias: 'novels',
              needAuth: true,
            },
          },
        ],
      },

      {
        path: ':bookId',
        redirect: { name: 'DraftTitle' },
        children: [
          {
            path: 'draft',
            redirect: { name: 'DraftTitle' },
            component: () =>
              import('@/layouts/novel-draft/LayoutNovelDraft.vue'),
            children: [
              {
                path: 'policy',
                name: 'DraftPolicy',
                component: () =>
                  import('@/pages/novel/draft/PageDraftPolicy.vue'),
                meta: {
                  i18nAlias: 'novel',
                  needAuth: true,
                },
              },
              {
                path: 'title',
                name: 'DraftTitle',
                component: () =>
                  import('@/pages/novel/draft/PageDraftTitle.vue'),
                meta: {
                  i18nAlias: 'novel',
                  needAuth: true,
                },
              },
              {
                path: 'contract',
                name: 'DraftContract',
                component: () =>
                  import('@/pages/novel/draft/PageDraftContract.vue'),
                meta: {
                  i18nAlias: 'novel',
                  needAuth: true,
                },
              },
              {
                path: 'audience',
                name: 'DraftAudience',
                component: () =>
                  import('@/pages/novel/draft/PageDraftAudience.vue'),
                meta: {
                  i18nAlias: 'novel',
                  needAuth: true,
                },
              },
              {
                path: 'genre',
                name: 'DraftGenre',
                component: () =>
                  import('@/pages/novel/draft/PageDraftGenre.vue'),
                meta: {
                  i18nAlias: 'novel',
                  needAuth: true,
                },
              },
              {
                path: 'blurb',
                name: 'DraftBlurb',
                component: () =>
                  import('@/pages/novel/draft/PageDraftBlurb.vue'),
                meta: {
                  i18nAlias: 'novel',
                  needAuth: true,
                },
              },
              {
                path: 'cover',
                name: 'DraftCover',
                component: () =>
                  import('@/pages/novel/draft/PageDraftCover.vue'),
                meta: {
                  i18nAlias: 'novel',
                  needAuth: true,
                },
              },
              {
                path: 'chapter',
                name: 'DraftChapter',
                component: () =>
                  import('@/pages/novel/draft/PageDraftChapter.vue'),
                meta: {
                  i18nAlias: 'novel',
                  needAuth: true,
                },
              },
            ],
          },

          {
            path: 'edit',
            component: () => import('@/layouts/novel-edit/LayoutNovelEdit.vue'),
            children: [
              {
                path: '',
                name: 'NovelEdit',
                component: () => import('@/pages/novel/edit/PageEdit.vue'),
                meta: {
                  i18nAlias: 'novel',
                  needAuth: true,
                },
              },
            ],
          },

          {
            path: 'chapters',
            redirect: { name: 'NovelChapterNew' },
            children: [
              {
                path: 'import',
                component: () =>
                  import('@/layouts/chapters/LayoutChapters.vue'),
                children: [
                  {
                    path: '',
                    name: 'NovelImport',
                    components: {
                      default: () =>
                        import('@/pages/novel/chapters/import/PageImport.vue'),
                      skeleton: () =>
                        import('@/layouts/chapters/SkeletonChaptersImport.vue'),
                    },
                    meta: {
                      i18nAlias: 'novel',
                      needAuth: true,
                    },
                  },
                ],
              },

              {
                path: 'new',
                component: () =>
                  import('@/layouts/chapters/LayoutChapters.vue'),
                children: [
                  {
                    path: '',
                    name: 'NovelChapterNew',
                    components: {
                      default: () =>
                        import('@/pages/novel/chapters/item/PageChapters.vue'),
                      skeleton: () =>
                        import('@/layouts/chapters/SkeletonChaptersEdit.vue'),
                    },
                    meta: {
                      i18nAlias: 'novel',
                      needAuth: true,
                    },
                  },
                ],
              },

              {
                path: ':chapterId',
                component: () =>
                  import('@/layouts/chapters/LayoutChapters.vue'),
                children: [
                  {
                    path: '',
                    name: 'NovelChapter',
                    components: {
                      default: () =>
                        import('@/pages/novel/chapters/item/PageChapters.vue'),
                      skeleton: () =>
                        import('@/layouts/chapters/SkeletonChaptersEdit.vue'),
                    },
                    meta: {
                      i18nAlias: 'novel',
                      needAuth: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
