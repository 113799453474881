<script setup lang="ts">
import ErrorService from '@/plugins/errorService';
import EmptyBlock from '@/components/EmptyBlock.vue';

interface IProps {
  preventFetch?: () => boolean;
  dataFetch?: () => Promise<void>;
}

const props = withDefaults(defineProps<IProps>(), {
  preventFetch: () => true,
  dataFetch: () => Promise.resolve(),
});

const { t } = useI18n();
const route = useRoute();

const isLoading = ref<boolean>(false);
const isFetchError = ref<boolean>(false);

onBeforeMount(async () => {
  if (props.preventFetch()) return;

  isLoading.value = true;

  try {
    await props.dataFetch();
  } catch (error) {
    ErrorService.onError(error, {
      location: `PageFetch component: ${route.name as string}`,
      urlFullPath: route.fullPath,
    });

    isFetchError.value = true;
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <q-page
    class="page-fetch"
    :class="{ column: isFetchError }"
  >
    <div
      v-if="isLoading"
      class="page-fetch__container container"
    >
      <slot name="skeleton"></slot>
    </div>

    <EmptyBlock
      v-else-if="isFetchError"
      :title="t('error.something_wrong')"
      :description="t('error.try_later')"
      class="q-py-xl"
    />

    <div
      v-else
      class="page-fetch__container container"
    >
      <slot></slot>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
.page-fetch {
  $this: 'page-fetch';

  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
</style>
