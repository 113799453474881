<script lang="ts" setup>
import IconBase from '@/components/IconBase/IconBase.vue';
import ButtonBase from '@/components/ButtonBase/ButtonBase.vue';

const props = defineProps<{
  modelValue: boolean;
  title?: string;
  showClose?: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
}>();

const inputHandler = (value: boolean) => emit('update:modelValue', value);

const closeModal = () => emit('update:modelValue', false);
</script>

<template>
  <q-dialog
    class="modal-bottom"
    position="bottom"
    :model-value="props.modelValue"
    @update:model-value="inputHandler"
  >
    <div class="modal-bottom__inner">
      <button-base
        v-if="props.showClose"
        class="modal-bottom__close"
        custom-size="empty"
        @click="closeModal"
      >
        <IconBase name="close" />
      </button-base>

      <slot></slot>
    </div>
  </q-dialog>
</template>

<style lang="scss">
.modal-bottom {
  &__inner {
    position: relative;
    padding: 16px 16px 24px;
    border-radius: 8px 8px 0 0 !important;
    background-color: $color-black-100;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    color: $color-grey-cool;
  }
}
</style>
