/**
 * Token manipulation
 */
class Token {
  /**
   * @property {string} key - storage access key
   */
  readonly key: string;

  /**
   * Create token manipulation instance
   * @param {string} key - storage access key
   * @example
   * new Token('storage_key')
   */
  constructor(key: string) {
    this.key = key;
  }

  /**
   * Get storage token value
   * @return {string | null } token value.
   */
  get(): string | null {
    return localStorage.getItem(this.key);
  }

  /**
   * Set storage value
   * @param {string} value - token value to store
   */
  set(value: string): void {
    localStorage.setItem(this.key, value);
  }

  /**
   * Remove storage value
   */
  remove(): void {
    localStorage.removeItem(this.key);
  }
}

/**
 * Access token manipulation (set/get/remove)
 * @example accessToken.get()
 */
export const accessToken = Object.freeze(new Token('auth_access_token'));

/**
 * Refresh token manipulation (set/get/remove)
 * @example refreshToken.get()
 */
export const refreshToken = Object.freeze(new Token('auth_refresh_token'));
