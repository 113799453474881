import type { RouteRecordRaw } from 'vue-router';

interface IStore {
  isUserLogin: boolean;
  isUserVerified: boolean;
}

export default function initAuthentificationRoutes(store: IStore) {
  const routes: RouteRecordRaw[] = [
    {
      path: '/auth',
      redirect: { name: 'Login' },
      component: () => import('@/layouts/auth/LayoutAuth/LayoutAuth.vue'),
      children: [
        {
          path: 'login',
          name: 'Login',
          component: () => import('@/pages/login/PageLogin/PageLogin.vue'),
          meta: {
            i18nAlias: 'login',
          },
        },
        {
          path: 'registration',
          name: 'Registration',
          component: () => import('@/pages/registration/PageRegistration.vue'),
          meta: {
            i18nAlias: 'registration',
          },
        },
        {
          // routeguard in vue page component
          path: 'verification',
          name: 'Verification',
          component: () => import('@/pages/verification/PageVerification.vue'),
          meta: {
            i18nAlias: 'verification',
          },
        },
        {
          path: 'verify',
          name: 'Verify',
          component: () => import('@/pages/verification/Verify.vue'),
          meta: {
            i18nAlias: 'verify',
          },
        },
        {
          path: 'forgot-password',
          name: 'ForgotPassword',
          component: () =>
            import('@/pages/forgot-password/PageForgotPassword.vue'),
          meta: {
            i18nAlias: 'forgot-password',
          },
        },
        {
          path: 'check-email',
          name: 'CheckEmail',
          component: () => import('@/pages/check-email/PageCheckEmail.vue'),
          meta: {
            i18nAlias: 'check-email',
          },
        },
        {
          path: 'reset-password',
          name: 'ResetPassword',
          component: () =>
            import('@/pages/reset-password/PageResetPassword.vue'),
          meta: {
            i18nAlias: 'reset-password',
          },
        },
      ],
      beforeEnter() {
        if (store.isUserLogin && store.isUserVerified) {
          return { name: 'Statistic' };
        }
        return true;
      },
    },
  ];

  return routes;
}
