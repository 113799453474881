import { createI18n } from 'vue-i18n';
import { useBaseStoreWithOut } from '@/stores/base';

import type {
  I18nSettings,
  IPageLocaleOptions,
  GetMsgFunction,
  I18nMessage,
  PageMessage,
  LOCALES,
} from './languages';

import { isLangCodeType, DEFAULT_LOCALE } from './languages';
import { getBaseMsgs, getPageMsgs } from './localTranslation';

const buildLoadFunction = function getLoadFunction(
  loadBaseMsg: GetMsgFunction,
  loadPageMsgs: GetMsgFunction
): GetMsgFunction {
  return async function loadMsg(
    options: IPageLocaleOptions
  ): Promise<PageMessage> {
    const baseMsgs = await loadBaseMsg(options);
    const pageMsgs = await loadPageMsgs(options);

    return {
      ...baseMsgs,
      [options.pageName]: pageMsgs,
    };
  };
};

const loadMsgs: GetMsgFunction = buildLoadFunction(getBaseMsgs, getPageMsgs);

// eslint-disable-next-line import/prefer-default-export
export async function setupI18n({
  router,
  appInstance,
  pageName,
}: I18nSettings) {
  const baseStore = useBaseStoreWithOut();
  const messages: I18nMessage = {};

  messages[DEFAULT_LOCALE] = await loadMsgs({
    pageName,
    langCode: DEFAULT_LOCALE,
  });

  const htmlLocale = document.documentElement.lang;

  const currentLocale: LOCALES = isLangCodeType(htmlLocale)
    ? htmlLocale
    : DEFAULT_LOCALE;

  baseStore.activeLangAlias = currentLocale;

  if (currentLocale !== DEFAULT_LOCALE) {
    messages[currentLocale] = await loadMsgs({
      pageName,
      langCode: currentLocale,
    });
  }

  const i18n = createI18n({
    messages,
    legacy: false,
    locale: currentLocale,
    fallbackLocale: DEFAULT_LOCALE,
  });

  router.beforeEach(async (to) => {
    const { activeLangAlias } = baseStore;
    const { i18nAlias } = to.meta;

    if (i18n.global.messages.value[activeLangAlias]?.[i18nAlias]) {
      return true;
    }

    const pageMsgs = await getPageMsgs({
      langCode: activeLangAlias,
      pageName: i18nAlias,
    });

    i18n.global.mergeLocaleMessage(activeLangAlias, {
      [i18nAlias]: pageMsgs,
    });

    return true;
  });

  appInstance.use(i18n);
}
