<script lang="ts" setup>
import IconBase from '@/components/IconBase/IconBase.vue';

const isOffline = ref<boolean>(!navigator.onLine);

const OFFLINE_CLASS = 'app-offline';

const toggleBodyClass = function toggleBodyClass() {
  if (isOffline.value) {
    document.body.classList.add(OFFLINE_CLASS);
  } else {
    document.body.classList.remove(OFFLINE_CLASS);
  }
};

const onlineHandler = function updateOnlineStatus(e: Event) {
  isOffline.value = e.type === 'offline';

  toggleBodyClass();
};

toggleBodyClass();

window.addEventListener('online', onlineHandler);
window.addEventListener('offline', onlineHandler);
</script>

<template>
  <div
    v-if="isOffline"
    class="offline-banner"
  >
    <IconBase name="wifi-x" />
    It looks like you're offline.
  </div>

  <router-view></router-view>
</template>

<style lang="scss" scoped>
.offline-banner {
  $this: 'offline-banner';

  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  background-color: $color-error-200;
  position: sticky;
  top: 0;
  z-index: 9999;
}
</style>
