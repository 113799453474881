<script setup lang="ts">
import type { QChipSlots } from 'quasar';
import { CHIP_TYPE } from '@/types/base';

interface IProps {
  bordered?: boolean;
  type?: CHIP_TYPE;
}

const props = withDefaults(defineProps<IProps>(), {
  bordered: false,
  type: CHIP_TYPE.white,
});

const slots = Object.keys(useSlots()) as [keyof QChipSlots];
</script>

<template>
  <q-chip
    class="chip-base rounded-borders text-caption-13 text-weight-medium"
    :class="[
      `chip-base--${props.type}`,
      {
        'chip-base--bordered': props.bordered,
      },
    ]"
    :ripple="false"
  >
    <template
      v-for="name in slots"
      :key="name"
      #[name]
    >
      <slot :name="name"></slot>
    </template>
  </q-chip>
</template>

<style lang="scss">
.chip-base {
  $this: 'chip-base';

  padding: 4px 8px;
  background-color: $color-black-950;
  height: auto;
  margin: 0;

  $sets: 'white' $color-black-950 $color-black-100 $color-secondary-200,
    'violet' $color-black-100 $color-secondary-500 $color-secondary-700,
    'grey' $color-black-800 $color-black-200 $color-black-300,
    'green' $color-success-800 $color-success-200 $color-success-300,
    'red' $color-error-800 $color-error-200 $color-error-300,
    'orange' $color-warning-800 $color-warning-100 $color-warning-200,
    'blue' $color-tertiary-500 $color-tertiary-100 $color-tertiary-200,
    'light_violet' $color-secondary-800 $color-secondary-200
      $color-secondary-300;

  @each $modifier, $textColor, $bgColor, $borderColor in $sets {
    &.#{$this}--#{$modifier} {
      color: $textColor;
      background-color: $bgColor;

      &.#{$this}--bordered {
        box-shadow: inset 0 0 0 1.5px $borderColor;
      }
    }
  }

  .q-chip__icon--remove {
    margin: 0 0 0 8px;
    width: 24px;
    height: 24px;
    color: transparent;
    opacity: 1;
    background: url('@/assets/images/icon-cancel-filled.svg') no-repeat center;
  }
}
</style>
