<script setup lang="ts">
import iconCrown from '@/assets/images/icon-crown.svg';
import iconStars from '@/assets/images/icon-stars.svg';

import { CHIP_TYPE } from '@/types/base';
import { BOOK_CONTRACT_TYPE } from '@/types/books';

import ChipBase from '@/components/ChipBase.vue';
import TooltipBase from '@/components/TooltipBase.vue';

interface IBadgeContractType {
  type: CHIP_TYPE;
  label: string;
  icon: string;
}

const props = withDefaults(
  defineProps<{
    type: BOOK_CONTRACT_TYPE;
    customSize?: 'sm' | 'md';
    showTooltip?: boolean;
    bordered?: boolean;
    showIcon?: boolean;
  }>(),
  {
    customSize: 'md',
    showTooltip: false,
    bordered: false,
    showIcon: false,
  }
);

const { t } = useI18n();

const typeMapper: Record<BOOK_CONTRACT_TYPE, CHIP_TYPE> = {
  [BOOK_CONTRACT_TYPE.exclusive]: CHIP_TYPE.grey,
  [BOOK_CONTRACT_TYPE.non_exclusive]: CHIP_TYPE.grey,
  [BOOK_CONTRACT_TYPE.ghostwrite]: CHIP_TYPE.grey,
  [BOOK_CONTRACT_TYPE.not_defined]: CHIP_TYPE.grey,
};

const labelMapper: Record<BOOK_CONTRACT_TYPE, string> = {
  [BOOK_CONTRACT_TYPE.exclusive]: t('book.contract.exclusive'),
  [BOOK_CONTRACT_TYPE.non_exclusive]: t('book.contract.non_exclusive'),
  [BOOK_CONTRACT_TYPE.ghostwrite]: t('book.contract.ghostwrite'),
  [BOOK_CONTRACT_TYPE.not_defined]: t('book.contract.not_defined_short'),
};

const iconMapper: Record<BOOK_CONTRACT_TYPE, string> = {
  [BOOK_CONTRACT_TYPE.exclusive]: iconCrown,
  [BOOK_CONTRACT_TYPE.non_exclusive]: iconStars,
  [BOOK_CONTRACT_TYPE.ghostwrite]: iconStars,
  [BOOK_CONTRACT_TYPE.not_defined]: iconStars,
};

const badge = computed<IBadgeContractType>(() => ({
  type: typeMapper[props.type],
  label: labelMapper[props.type],
  icon: iconMapper[props.type],
}));
</script>

<template>
  <chip-base
    class="chip-book-contract-type"
    :class="`chip-book-contract-type--${props.customSize}`"
    :type="badge.type"
    :bordered="props.bordered"
  >
    <img
      v-if="props.showIcon"
      :src="badge.icon"
      alt="book cover"
      width="16"
      height="16"
      class="q-mr-sm"
    />

    <span class="chip-book-contract-type__inner">{{ badge.label }}</span>

    <tooltip-base
      v-if="props.showTooltip"
      dense
    >
      {{ badge.label }}
    </tooltip-base>
  </chip-base>
</template>

<style lang="scss" scoped>
.chip-book-contract-type {
  $this: 'chip-book-contract-type';

  &--sm {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  &__inner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
