import { defineStore } from 'pinia';
import { store } from '@/stores';

import type { ApiUserResponse } from '@/types/user';
import { apiGetUserData } from '@/api/user';
import { accessToken, refreshToken } from '@/helpers/tokens';

interface IUserStore {
  userData: ApiUserResponse | null;
}

export const useUserStore = defineStore('user', {
  state(): IUserStore {
    return {
      userData: null,
    };
  },

  actions: {
    /**
     * Remove user data.
     * Clean access/refresh tokens and stored user data
     *
     * @example
     * userStore.cleanUserData()
     */
    cleanUserData() {
      accessToken.remove();
      refreshToken.remove();

      this.userData = null;
    },

    /**
     * User data request.
     * Make api call and set data to store
     *
     * @example
     * await userStore.getUserData()
     */
    async getUserData() {
      if (!accessToken.get() && !refreshToken.get()) {
        throw new Error('Internal error. Need access/refresh tokens.');
      }

      const userData = await apiGetUserData();

      this.userData = userData.data;
    },
  },

  getters: {
    /**
     * Is user logged in to dashboard
     *
     * @returns {boolean} login state
     * @example
     * userStore.isUserLogin -> true/false
     */
    isUserLogin(): boolean {
      return !!this.userData;
    },

    /**
     * Is user verified  and can access dashboard
     *
     * @returns {boolean} verification state
     * @example
     * userStore.isUserVerified -> true/false
     */
    isUserVerified(): boolean {
      return !!this.userData?.is_verified;
    },

    /**
     * Users account money balance
     *
     * @returns {number} money amount
     * @example
     * userStore.userBalance -> 25.5
     */
    userBalance(): number {
      return this.userData?.balance ?? 0;
    },
  },
});

/**
 * Need to be used outside the setup to init store modules
 *
 * @see {@link https://pinia.vuejs.org/core-concepts/outside-component-usage.html}
 */
export function useUserStoreWithOut() {
  return useUserStore(store);
}
