<script setup lang="ts">
import IconBase from '@/components/IconBase/IconBase.vue';
import ButtonBase from '@/components/ButtonBase/ButtonBase.vue';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    showClose?: boolean;
    title?: string;
    description?: string | null;
  }>(),
  {
    showClose: true,
    title: '',
    description: null,
  }
);

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
}>();

const inputHandler = (value: boolean) => emit('update:modelValue', value);

const closeModal = () => emit('update:modelValue', false);
</script>

<template>
  <q-dialog
    class="modal-base"
    transition-duration="200"
    transition-show="jump-down"
    transition-hide="jump-up"
    :model-value="props.modelValue"
    @update:model-value="inputHandler"
  >
    <div class="modal-base__wrap">
      <div class="modal-base__header">
        <div class="modal-head">
          <div v-if="props.title" class="modal-title text-title-l text-weight-bold">
            {{ props.title }}
          </div>
          <div v-if="props.description" v-html="props.description" class="modal-description" />
        </div>
        <button-base
          v-if="props.showClose"
          class="modal-base__close"
          custom-size="empty"
          @click="closeModal"
        >
          <IconBase name="close" />
        </button-base>
      </div>
      <div class="modal-base__body">
        <slot />
      </div>
      <div v-if="$slots.footer" class="modal-base__footer">
        <slot name="footer" />
      </div>
    </div>
  </q-dialog>
</template>

<style lang="scss" scoped>
.modal-base {
  $this: 'modal-base';

  &__wrap {
    background-color: $color-black-100;
    border-radius: $border-radius-medium;
    box-shadow: none !important;
    max-width: 456px;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }

  &__header {
    display: flex;
    column-gap: 16px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px 24px 12px;

    .modal-description {
      margin-top: 8px;
      color: var(--color-black-500);
    }
  }

  &__body {
    overflow: auto;
    padding: 12px 24px;
    min-height: 50px;
  }

  &__footer {
    padding: 12px 24px;
  }

  &__close {
    width: 32px;
    height: 32px;
    margin: -6px;
  }
}
</style>

<style lang="scss">
.modal-base {
  $this: 'modal-base';

  // .q-dialog__backdrop {
  //   background: rgba(6, 6, 6, 0.4);
  //   backdrop-filter: blur(2px);
  // }

  .q-dialog__inner--minimized {
    padding: 16px;
  }
}
</style>
