<script setup lang="ts">
import ImagePlaceholder from '@/assets/images/placeholder-not-found.svg';

const slots = useSlots();

const props = defineProps<{
  title: string;
  description: string;
  image?: string;
}>();
</script>

<template>
  <div class="empty-block text-center">
    <figure class="empty-block__figure mb-40">
      <img
        :src="props.image || ImagePlaceholder"
        :alt="props.title"
        width="128"
        height="128"
      />
    </figure>

    <h3 class="text-h4 text-weight-medium q-mb-md">{{ props.title }}</h3>

    <p
      class="text-title"
      v-html="props.description"
    ></p>

    <div
      v-if="!!slots.action"
      class="mt-40"
    >
      <slot name="action"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.empty-block {
  $this: 'empty-block';

  margin: auto;
  max-width: 420px;
  width: 100%;

  &__figure {
    line-height: 0;
  }
}
</style>
