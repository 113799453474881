<script setup lang="ts">
import type { QTooltipProps, QTooltip } from 'quasar';

interface IProps {
  arrowed?: boolean;
  bordered?: boolean;
  dense?: boolean;
  anchor?: QTooltipProps['anchor'];
  self?: QTooltipProps['self'];
  arrowPosition?: 'top' | 'bottom' | 'left' | 'right';
  theme?: 'light' | 'dark';
}

const props = withDefaults(defineProps<IProps>(), {
  arrowed: true,
  bordered: false,
  dense: false,
  anchor: 'top middle',
  self: 'bottom middle',
  arrowPosition: 'bottom',
  theme: 'light',
});

const emit = defineEmits<{
  (event: 'init', value: Ref<QTooltip | undefined>): void;
}>();

const tooltipRef = ref<QTooltip>();
const isVisible = ref<boolean>(false);

onMounted(() => {
  emit('init', tooltipRef);
});
</script>

<template>
  <q-tooltip
    ref="tooltipRef"
    v-model="isVisible"
    class="tooltip-base"
    :class="[
      `tooltip-base--${props.theme}`,
      {
        'tooltip-base--bordered': props.bordered,
        'tooltip-base--dense': props.dense,
      },
    ]"
    :delay="300"
    transition-duration="250"
    max-width="240px"
    :offset="[-10, -10]"
    :anchor="props.anchor"
    :self="props.self"
  >
    <div class="tooltip-base__inner text-body">
      <slot></slot>

      <svg
        v-if="props.arrowed"
        class="tooltip-base__arrow"
        :class="`tooltip-base__arrow--${props.arrowPosition}`"
        width="26"
        height="10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          v-if="props.bordered"
          d="M1 2h4c2 0 4.833 7 8 7s6-7 8-7h4"
          stroke="currentColor"
          stroke-width="1"
          stroke-linecap="round"
        />
        <path
          class="tooltip-base__arrow-inner"
          d="M5 1h16c-2.5 0-5 7-8 7S8 1 5 1Z"
          stroke="#fff"
          stroke-width=".5"
          stroke-linecap="round"
        />
      </svg>
    </div>
  </q-tooltip>
</template>

<style lang="scss">
.tooltip-base {
  $this: 'tooltip-base';

  background-color: transparent;
  padding: 0;

  &--bordered {
    .#{$this}__inner {
      border: $border-active;
    }

    .#{$this}__inner:after {
      border: $border-active;
    }
  }

  &--dense {
    .#{$this}__inner {
      padding: 10px;
    }
  }

  &--dark {
    .#{$this}__inner {
      background-color: $color-black-950;
      color: $color-black-100;
    }

    .#{$this}__arrow-inner {
      fill: $color-black-950;
      stroke: $color-black-950;
    }
  }

  &__inner {
    margin: 20px;
    padding: 16px 24px;
    background-color: $color-black-100;
    color: $color-black-950;
    border-radius: $border-radius-medium;
    box-shadow: $shadow-medium-dark;
    position: relative;
  }

  &__arrow {
    position: absolute;
    z-index: 1;
    fill: $color-black-100;

    &--bottom {
      bottom: -9px;
      left: 50%;
      transform: translateX(-50%);
    }

    &--top {
      top: -9px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
    }

    &--right {
      right: -16px;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
    }

    &--left {
      left: -16px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
    }
  }

  &__arrow-inner {
    fill: $color-white;
  }
}
</style>
