<script setup lang="ts">
import bookPreviewImg from '@/assets/images/placeholder-book.svg';
import { formatDate } from '@/utils';
import type { IBookExtend } from '@/types/books';

const props = withDefaults(
  defineProps<{
    book: IBookExtend;
    clickable?: boolean;
  }>(),
  {
    clickable: true,
  }
);

const emit = defineEmits<{
  (event: 'click'): void;
}>();

const clickHandler = () => emit('click');

const { t } = useI18n();
</script>

<template>
  <figure
    class="card-book-preview rounded-borders flex no-wrap items-center non-selectable"
    :class="{ 'card-book-preview--clickable': props.clickable }"
    tabindex="0"
    @click="clickHandler"
  >
    <img
      :src="props.book.cover || bookPreviewImg"
      alt="book cover"
      width="40"
      height="54"
      class="card-book-preview__img"
    />

    <figcaption class="card-book-preview__caption">
      <span
        class="card-book-preview__title block text-body-s text-weight-medium text-no-wrap ellipsis"
      >
        {{ props.book.title }}
      </span>

      <span
        class="card-book-preview__date block text-caption-13 text-no-wrap ellipsis"
      >
        {{ t('text.last_update') }}
        {{ formatDate(props.book.updated_at, 'DD/MM/YYYY') }}
      </span>
    </figcaption>
  </figure>
</template>

<style lang="scss" scoped>
.card-book-preview {
  $this: 'card-book-preview';

  max-width: 254px;

  &--clickable {
    cursor: pointer;

    // transition: background-color $anim-primary, filter $anim-primary;

    // &:hover,
    // &:focus-visible {
    //   filter: $drop-shadow-medium;
    //   background-color: $color-black-300;
    // }

    // &:active {
    //   background-color: $color-black-400;
    // }
  }

  &__img {
    object-fit: cover;
    border-radius: $border-radius-small;
    flex-shrink: 0;
    margin-right: 12px;
  }

  &__title {
    margin-bottom: 2px;
  }

  &__caption {
    min-width: 0;
  }

  &__date {
    opacity: $opacity-medium;
  }
}
</style>
