<script setup lang="ts">
import type { QFile, QRejectedEntry } from 'quasar';

import ButtonBase from '@/components/ButtonBase/ButtonBase.vue';

const props = defineProps<{
  modelValue: File | FileList | null;
  rules?: ((val: File) => string | true)[];
  disable?: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: File | FileList | null): void;
  (event: 'rejected', value: QRejectedEntry[]): void;
}>();

const { t } = useI18n();

const refFileInput = ref<QFile | null>(null);
const isDragActive = ref<boolean>(false);

const rerenderKey = ref<number>(0);

const fileNames = computed<string>(() => {
  if (!props.modelValue) {
    return '';
  }

  if (props.modelValue instanceof FileList) {
    return Array.from(props.modelValue)
      .map((el) => el.name)
      .join(', ');
  }

  return props.modelValue.name;
});

const inputHandler = async (value: File | FileList | null) => {
  rerenderKey.value += 1;

  emit('update:modelValue', value);

  await nextTick();

  refFileInput.value?.validate();
};

const pickFiles = () => refFileInput.value?.pickFiles();

const isFilePicked = computed<boolean>(() => {
  if (props.modelValue instanceof FileList) {
    return props.modelValue.length !== 0;
  }

  return !!props.modelValue;
});

const rejectFile = (err: QRejectedEntry[]) => emit('rejected', err);

const dragActive = (status: boolean) => {
  isDragActive.value = status;
};

const dragDrop = (e: DragEvent) => {
  if (e.dataTransfer) {
    refFileInput.value?.addFiles(e.dataTransfer.files);
  }

  dragActive(false);
};
</script>

<template>
  <div
    class="uploader-base column reverse"
    :class="{
      'uploader-base--disabled': props.disable,
    }"
  >
    <q-file
      ref="refFileInput"
      v-bind="$attrs"
      :model-value="props.modelValue"
      :rules="props.rules"
      :disable="props.disable"
      no-error-icon
      hide-bottom-space
      lazy-rules
      @rejected="rejectFile"
      @update:model-value="inputHandler"
    />

    <div
      class="uploader-base__inner column items-center justify-center no-wrap rounded-borders"
      :class="{
        'uploader-base__inner--filled': isFilePicked,
        'uploader-base__inner--hover': isDragActive,
      }"
      tabindex="0"
      @dragover.prevent="dragActive(true)"
      @dragleave.prevent="dragActive(false)"
      @drop.prevent="dragDrop"
      @click="pickFiles"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <svg
          v-if="isFilePicked"
          :key="rerenderKey"
          class="uploader-base__icon"
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="upload-check"
            d="m7.5 12.5 3 3 7-7"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="upload-circle"
            d="M12.5 22c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <img
          v-else
          src="@/assets/images/icon-cloud-download.svg"
          alt="cloud-download"
          width="24"
          height="24"
        />
      </transition>

      <span
        v-if="fileNames"
        class="uploader-base__file-name"
      >
        {{ fileNames }}
      </span>

      <span class="text-weight-medium q-mt-md q-mb-xs">
        {{ t('text.drag_drop') }}
      </span>

      <span class="uploader-base__or q-mb-xs">
        {{ t('text.or') }}
      </span>

      <button-base
        class="uploader-base__btn"
        color="secondary"
        custom-size="sm"
      >
        {{ isFilePicked ? t('button.replace_file') : t('button.select_file') }}
      </button-base>
    </div>
  </div>
</template>

<style lang="scss">
.uploader-base {
  $this: 'uploader-base';

  &--disabled {
    opacity: 0.64;
    cursor: not-allowed;

    .#{$this}__inner {
      pointer-events: none;
    }
  }

  .q-field--error + .#{$this}__inner {
    border-color: $color-warning-500;
  }

  &__inner {
    cursor: pointer;
    border: 1.5px dashed $color-secondary-400;
    transition: background-color $anim-primary;
    padding: 16px 24px;
    min-height: 198px;

    &:hover,
    // &:focus,
    &:active,
    &--hover {
      background-color: $color-black-200;
    }

    &--filled {
      border-color: $color-success-500;
    }
  }

  &__icon {
    color: $color-secondary-400;
  }

  &__file-name {
    text-align: center;
    margin-top: 6px;
    color: $color-success-500;
    word-break: break-all;
  }

  &__or {
    color: $color-black-700;
  }

  &__btn {
    max-width: 160px;
    width: 100%;
  }

  #upload-circle {
    stroke-dashoffset: 100;
    stroke-dasharray: 100;
    animation: move-check 1s cubic-bezier(0.4, 0, 0.6, 1) forwards;
  }

  #upload-check {
    stroke-dashoffset: 20;
    stroke-dasharray: 20;
    animation: move-check 0.2s cubic-bezier(0.4, 0, 0.6, 1) 0.8s forwards;
  }

  .q-field__control {
    display: none;
  }

  .q-field__bottom {
    padding: 4px 0 0;
  }

  .q-field__messages {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.04px;
  }

  .q-field--error {
    .q-field__messages {
      color: $color-warning-500;
    }
  }
}

.fade-enter-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from {
  opacity: 0;
}

@keyframes move-check {
  to {
    stroke-dashoffset: 0;
    color: $color-success-500;
  }
}
</style>
