<script setup lang="ts">
export interface ITabOption {
  name: string;
  label: string;
  icon?: string;
}

interface IProps {
  modelValue: string;
  options: ITabOption[];
  colored?: boolean;
  flat?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  colored: false,
  flat: false,
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: string | number | null): void;
}>();

const inputHandler = (value: string | number | null) =>
  emit('update:modelValue', value);
</script>

<template>
  <q-tabs
    class="tabs-flat"
    :model-value="props.modelValue"
    indicator-color="transparent"
    inline-label
    no-caps
    @update:model-value="inputHandler"
  >
    <q-tab
      v-for="option in props.options"
      :key="option.name"
      class="tabs-flat__item rounded-borders"
      :class="{
        'tabs-flat__item--colored': props.colored,
        'tabs-flat__item--elevate': !props.flat,
      }"
      :ripple="false"
      :name="option.name"
      :label="option.label"
      :icon="option.icon"
    />
  </q-tabs>
</template>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
.tabs-flat {
  $this: 'tabs-flat';

  &__item {
    width: 100%;
    min-height: 40px;
    padding: 0 24px;
    background-color: $color-black-100;
    border: 1.5px solid transparent;
    transition: border-color $anim-primary, box-shadow $anim-primary;

    &.q-tab--active {
      border-color: $color-black-200;

      .q-focus-helper {
        opacity: 0 !important;
      }
    }

    &--colored.q-tab--active {
      background-color: #f3f6ff;
      border-color: #3b5fca;
    }

    &--elevate.q-tab--active {
      box-shadow: $shadow-medium-dark;
    }

    .q-tab__content {
      padding: 0;
    }

    .q-tab__label {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.16px;
      font-weight: 500;
    }

    .q-focus-helper {
      &::after {
        display: none;
      }
    }
  }

  .q-tabs__arrow {
    display: none;
  }

  .q-tabs__content {
    overflow: visible;
  }
}

body {
  .tabs-flat__item.q-manual-focusable--focused > .q-focus-helper,
  .tabs-flat__item.q-focusable:focus > .q-focus-helper,
  .tabs-flat__item.q-hoverable:hover > .q-focus-helper {
    opacity: 1;
    background-color: transparent;
  }

  .tabs-flat__item.q-manual-focusable--focused > .q-focus-helper::before,
  .tabs-flat__item.q-focusable:focus > .q-focus-helper::before,
  .tabs-flat__item.q-hoverable:hover > .q-focus-helper::before {
    opacity: 1;
  }

  /* base */
  .tabs-flat__item .q-focus-helper::before {
    background-color: $color-black-200;
  }

  .tabs-flat__item.q-manual-focusable--focused > .q-focus-helper::before,
  .tabs-flat__item.q-focusable:focus > .q-focus-helper::before {
    background-color: $color-black-300;
  }
}
</style>
