import type { App as Application, Component } from 'vue';

const componentFiles = import.meta.glob<unknown>('@/components/**/*.vue', {
  eager: true,
});

export default function registerComponents(app: Application) {
  Object.entries(componentFiles).forEach(([path, module]) => {
    const componentName = path.split('/').reverse()[0]?.split('.')[0];

    if (!componentName) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    app.component(componentName, module.default as Component);
  });
}
