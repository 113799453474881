<script setup lang="ts">
import type { QInput, QInputProps, QInputSlots } from 'quasar';

export interface InputBaseProps extends QInputProps {
  modelValue: string | number | null;
  label?: string;
  filled?: boolean;
  fixedHeight?: boolean;
  requireLabel?: boolean;
  disable?: boolean;
}

const props = withDefaults(defineProps<InputBaseProps>(), {
  label: '',
  filled: false,
  fixedHeight: false,
  requireLabel: false,
  disable: false,
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: string | number | null): void;
}>();

const inputHandler = (value: string | number | null) =>
  emit('update:modelValue', value);

const inputRef = ref<QInput | null>(null);
const slots = Object.keys(useSlots() ?? {}) as [keyof QInputSlots];

defineExpose<{
  inputRef: Ref<QInput | null>;
}>({
  inputRef,
});
</script>

<template>
  <q-input
    ref="inputRef"
    class="input-base text-body"
    :class="{
      'input-base--labeled': props.label,
      'input-base--filled': props.filled,
      'input-base--fixed-height': props.fixedHeight,
      'input-base--require-label': props.requireLabel,
    }"
    v-bind="props"
    :model-value="props.modelValue"
    :label="props.label"
    :disable="props.disable"
    outlined
    stack-label
    no-error-icon
    hide-bottom-space
    lazy-rules
    @update:model-value="inputHandler"
  >
    <template
      v-for="name in slots"
      :key="name"
      #[name]
    >
      <slot :name="name"></slot>
    </template>
  </q-input>
</template>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
.input-base {
  $this: 'input-base';

  .q-field__control {
    height: auto;
    min-height: 48px;
    padding: 0 16px;
    border-radius: $border-radius-medium;

    &::before {
      background-color: $color-black-100;
      border: 1.5px solid $color-black-300;
      transition: border-color $anim-fast, background-color $anim-fast;
    }

    &::after {
      display: none;
    }

    &:hover {
      &::before {
        border-color: $color-black-950;
      }
    }
  }

  .q-field__control-container {
    position: initial;
    z-index: 1;
  }

  .q-field__native {
    letter-spacing: inherit;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    text-overflow: ellipsis;
    color: $color-black-950;

    &::placeholder {
      color: $color-black-400;
      font-size: 15px;
      line-height: 23px;
      letter-spacing: 0;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: $color-black-300;
      border-radius: 3px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: $color-secondary-500;
      border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background-color: $color-secondary-500;
    }
  }

  .q-field__native:-webkit-autofill + .q-field__label,
  .q-field__input:-webkit-autofill + .q-field__label {
    transform: none;
  }

  &.q-field--outlined .q-field__native:-webkit-autofill,
  &.q-field--outlined .q-field__input:-webkit-autofill {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .q-field__label {
    @include text-caption-13;

    color: $color-black-600;
    transform: none;
    transition: none;
    top: -26px;
    max-width: 100%;
  }

  .q-field__marginal {
    height: auto;
    color: initial;
    z-index: 1;
  }

  .q-field__bottom {
    padding: 4px 0 0;
    min-height: auto;
  }

  .q-field__messages,
  .q-field__counter {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.04px;
    color: $color-black-500;
  }

  .q-field__append .q-field__focusable-action {
    opacity: 0;
    pointer-events: none;
    color: transparent;
    background: url('@/assets/icons/close.svg') no-repeat center;
  }

  &--labeled {
    padding-top: 26px;
  }

  &--require-label {
    .q-field__label {
      &::after {
        content: '*';
        display: inline;
        color: $color-error-500;
      }
    }
  }

  &--filled {
    .q-field__native {
      &::placeholder {
        color: $color-black-500;
      }
    }

    .q-field__control {
      &::before {
        background-color: $color-black-200;
      }
    }

    &.q-field--readonly {
      .q-field__control {
        &:hover {
          &::before {
            background-color: $color-black-200;
            border-color: $color-black-300;
          }
        }
      }
    }
  }

  &--fixed-height {
    .q-field__control {
      min-height: 158px;
    }

    .q-field__native {
      resize: none;
    }
  }

  &.q-textarea {
    .q-field__control-container {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    // .q-field__control {
    //   min-height: auto;
    // }
  }
}

.input-base.q-field--focused,
.input-base.q-field--highlighted {
  .q-field__control {
    &::before {
      background-color: $color-black-100;
      border-color: $color-secondary-500;
    }

    &:hover {
      &::before {
        border-color: $color-secondary-500;
      }
    }

    .q-field__append .q-field__focusable-action {
      pointer-events: all;
      opacity: 1;
    }
  }
}

.input-base.q-field--error {
  .q-field__control {
    &::before {
      background-color: $color-warning-100;
      border-color: $color-warning-500;
    }

    &:hover {
      &::before {
        border-color: $color-warning-500;
      }
    }
  }

  .q-field__messages,
  .q-field__counter {
    color: $color-warning-500;
  }
}

.input-base.q-field--error.q-field--focused {
  .q-field__control {
    &::before {
      background-color: $color-black-100;
    }
  }
}

.input-base.q-field--readonly.q-field--outlined {
  .q-field__control {
    &::before {
      border-style: solid;
    }
  }

  .q-field__native {
    color: $color-black-700;
  }
}
</style>
