<script setup lang="ts">
import { CHIP_TYPE } from '@/types/base';
import { BOOK_STATUS } from '@/types/books';

import IconBase from '@/components/IconBase/IconBase.vue';

interface IBadgeStatus {
  type: CHIP_TYPE;
  label: string;
  icon: string;
}

const props = defineProps<{
  type: BOOK_STATUS;
}>();

const { t } = useI18n();

const typeMapper: Record<BOOK_STATUS, CHIP_TYPE> = {
  [BOOK_STATUS.all]: CHIP_TYPE.grey,
  [BOOK_STATUS.draft]: CHIP_TYPE.red,
  [BOOK_STATUS.rejected]: CHIP_TYPE.red,
  [BOOK_STATUS.under_review]: CHIP_TYPE.orange,
  [BOOK_STATUS.wait_list]: CHIP_TYPE.orange,
  [BOOK_STATUS.contract_signing]: CHIP_TYPE.orange,
  [BOOK_STATUS.proofreading]: CHIP_TYPE.orange,
  [BOOK_STATUS.need_revise]: CHIP_TYPE.orange,
  [BOOK_STATUS.preparing_publishing]: CHIP_TYPE.orange,
  [BOOK_STATUS.published]: CHIP_TYPE.green,
  [BOOK_STATUS.request_deleted]: CHIP_TYPE.light_violet,
};

const labelMapper: Record<BOOK_STATUS, string> = {
  [BOOK_STATUS.all]: t('book.status.published'),
  [BOOK_STATUS.draft]: t('book.status.draft'),
  [BOOK_STATUS.rejected]: t('book.status.rejected'),
  [BOOK_STATUS.under_review]: t('book.status.under_review'),
  [BOOK_STATUS.wait_list]: t('book.status.wait_list'),
  [BOOK_STATUS.contract_signing]: t('book.status.contract_signing'),
  [BOOK_STATUS.proofreading]: t('book.status.proofreading'),
  [BOOK_STATUS.need_revise]: t('book.status.need_revise'),
  [BOOK_STATUS.preparing_publishing]: t('book.status.preparing_publishing'),
  [BOOK_STATUS.published]: t('book.status.published'),
  [BOOK_STATUS.request_deleted]: t('book.status.deleted'),
};

const iconMapper: Record<BOOK_STATUS, string> = {
  [BOOK_STATUS.all]: 'circle-check-16',
  [BOOK_STATUS.draft]: 'pen-16',
  [BOOK_STATUS.rejected]: 'clock-16',
  [BOOK_STATUS.under_review]: 'clock-16',
  [BOOK_STATUS.wait_list]: 'clock-16',
  [BOOK_STATUS.contract_signing]: 'clock-16',
  [BOOK_STATUS.proofreading]: 'clock-16',
  [BOOK_STATUS.need_revise]: 'clock-16',
  [BOOK_STATUS.preparing_publishing]: 'clock-16',
  [BOOK_STATUS.published]: 'circle-check-16',
  [BOOK_STATUS.request_deleted]: 'trash',
};

const badge = computed<IBadgeStatus>(() => ({
  type: typeMapper[props.type],
  label: labelMapper[props.type],
  icon: iconMapper[props.type],
}));
</script>

<template>
  <div
    class="badge-book-status text-caption-13 text-weight-medium"
    :class="`badge-book-status--${badge.type}`"
  >
    <IconBase
      :name="badge.icon"
      size="sm"
    />
    {{ badge.label }}
  </div>
</template>

<style lang="scss" scoped>
.badge-book-status {
  $this: 'badge-book-status';

  display: flex;
  align-items: center;
  column-gap: 4px;
  padding: 0 8px;

  &--grey {
    color: $color-black-950;
  }

  &--green {
    color: $color-success-600;
  }

  &--orange {
    color: $color-warning-600;
  }

  &--red {
    color: $color-error-600;
  }

  &--light_violet {
    color: $color-pink-dragon;
  }
}
</style>
