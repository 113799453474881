import { IChartPoint, IChartPointFormated } from '@/types/base';
import { IPaymentMethod, PAYMENT_METHOD } from '@/types/payments';

// AUTHOR
// =======================================================
export interface IBookAuthor {
  id: number;
  name: string;
  pen_name: string;
  avatar: string;
  description: string;
  status_id: number;
  gender_id: number | null;
  created_at: string;
  updated_at: string;
}

// GENRE
// =======================================================
export interface IBookGenre {
  id: number;
  name: string;
  icon: string;
}

// TAG
// =======================================================
export interface IBookTag {
  id: number;
  name: string;
}

export interface IBookTagCategory {
  name: string;
  tags: IBookTag[];
}

// BOOK
// =======================================================

export enum BOOK_STATUS {
  all = 0,
  published = 1,
  under_review = 2,
  draft = 3,
  request_deleted = 4,
  preparing_publishing = 5,
  contract_signing = 6,
  proofreading = 7,
  need_revise = 8,
  rejected = 9,
  wait_list = 10,
}

export enum BOOK_TYPE {
  ongoing = 0,
  completed = 1,
}

export enum BOOK_CONTRACT_TYPE {
  exclusive = 1,
  non_exclusive = 2,
  ghostwrite = 3,
  not_defined = 4,
}

export enum BOOK_GENDER {
  male = 1,
  female = 2,
  other = 3,
}

export enum BOOK_AGE_RESTRICTION {
  adolescent = 0,
  adult = 1,
}

export enum CREATE_CHAPTER_TYPE {
  scratch = 'scratch',
  import = 'import',
}

export enum DRAFT_ROUTE {
  policy = 'DraftPolicy',
  title = 'DraftTitle',
  contract = 'DraftContract',
  audience = 'DraftAudience',
  genre = 'DraftGenre',
  blurb = 'DraftBlurb',
  cover = 'DraftCover',
  chapter = 'DraftChapter',
}

export type TContractRule = (val: BOOK_CONTRACT_TYPE | null) => string | true;

interface IBookBase {
  id: number;
  title: string;
  updated_at: string;
}

export interface IBookExtend extends IBookBase {
  description: string | null;
  plot_summary: string | null;
  cover: string | null;
  status: BOOK_STATUS;
  is_completed: boolean;
  chapter_count: number;
  word_count: number;
  contract_type_id: BOOK_CONTRACT_TYPE;
  publication_links: string[];
  views: number;
  likes: number;
  added_in_library: number;
  genres: IBookGenre[];
  tags: IBookTag[];
  age_restriction: BOOK_AGE_RESTRICTION | null;
  gender_id: BOOK_GENDER | null;
  updated_at: string;
  is_contract_sign_sent: boolean;
  book_volume: number | null;
  full_book_delivery_date: string | null;
  estimated_chapters_count: number;
  ready_chapters_count: number;
  one_sentence_summary: string;
  quote: string;
}

export interface IBookWithTimestamp extends IBookExtend {
  timestamp: number;
}

export type IBookDraft = MaybeNull<{
  title: string;
  is_completed: BOOK_TYPE; // 0 - false, 1 - true
  contract_type_id: BOOK_CONTRACT_TYPE;
  publication_links: string[] | null;
  gender_id: BOOK_GENDER;
  age_restriction: BOOK_AGE_RESTRICTION;
  genre_ids: number[];
  tag_ids: number[];
  description: string;
  plot_summary: string;
  cover: string;
  is_uploading_cover: boolean;
  book_volume: number | null;
  estimated_chapters_count: number;
  ready_chapters_count: number;
  full_book_delivery_date: string | null;
  one_sentence_summary: string;
  quote: string;
}>;

// METRICS
// =======================================================
export interface IBookMetrics {
  valid_reads: IChartPoint[];
  readers: IChartPoint[];
  profit_earned: IChartPoint[];
  daily_duration: IChartPoint[];
  daily_reading_chapters: IChartPoint[];
  likes: IChartPoint[];
  total_reads: IChartPoint[];
  valid_reading_ratio: IChartPoint[];
}

interface IBookStatistic {
  chapter_count: number;
  word_count: number;
  accumulated_profit: number;
}

export interface IBookStatisticData {
  book: IBookBase;
  metrics: IBookMetrics;
  statistics: IBookStatistic;
}

export interface IBookReleaseStatisticData {
  chapter_count: number,
  word_count: number,
  accumulated_profit: number,
}

export interface IBookRetentionRate {
  date: string;
  values: number[];
}

export interface IBookChaptersRetentionRate {
  chapter: number;
  is_free: boolean;
  data: Record<string, number>;
}

export interface IBookGenderMetric {
  gender: number;
  count: number;
}

export interface ICountryMetric {
  code: string;
  count: number;
}

type FormatPoints<Type> = {
  [Property in keyof Type]: IChartPointFormated[];
};

export type TFormatedMetrics = FormatPoints<IBookMetrics>;

export interface IBookStatisticDataFormated {
  book: IBookBase;
  metrics: TFormatedMetrics;
  statistics: IBookStatistic;
}

// CONTRACT
// =======================================================

export enum CONTRACT_ROUTE {
  info = 'ContractInfo',
  documents = 'ContractDocuments',
  address = 'ContractAddress',
  payments = 'ContractPayments',
}

export enum DOCUMENT_TYPE {
  other = 0,
  id_card = 1,
  passport = 2,
  driver_license = 3,
}

export enum BOOK_CONTRACT_FIELD {
  first_name = 'first_name',
  last_name = 'last_name',
  pen_name = 'pen_name',
  personal_email = 'email',
  citizenship = 'citizenship',
  document_type = 'document_type',
  document_custom_type = 'document_custom_type',
  document_number = 'document_id',
  house = 'house',
  street = 'street',
  country = 'country',
  city = 'city',
  payment_type = 'payment_type',
  payment_account = 'payment_account',
  payment_receiver = 'payment_receiver',
  can_receive_in_euro = 'can_receive_in_euro',
}

export interface IBookContract {
  first_name: string;
  last_name: string;
  pen_name: string;
  email: string;
  citizenship: string;
  document_type: DOCUMENT_TYPE | null;
  another_document_name: string | null;
  document_no: string;
  building: string;
  street: string;
  country: string;
  city: string;
  payment_method: IPaymentMethod;
  payment_account: string;
  payment_receiver: string;
  can_receive_in_euro: boolean;
  book_volume: number | null;
  full_book_delivery_date: string | null;
}

export interface IBookContractDraft {
  first_name: string;
  last_name: string;
  pen_name: string;
  email: string;
  citizenship: string;
  document_type: DOCUMENT_TYPE | null;
  another_document_name: string;
  document_no: string;
  country: string;
  city: string;
  street: string;
  building: string;
  zip_code: string;
  payment_method: PAYMENT_METHOD | null;
  payment_account: string;
  payment_receiver: string;
  payment_can_receive_in_euro: boolean;
  book_volume: number | null;
  full_book_delivery_date: string | null;
  estimated_chapters_count: number;
  ready_chapters_count: number;
  one_sentence_summary: string;
  quote: string;
}

export interface IBookStatusHistory {
  status: BOOK_STATUS;
  date: number | null;
}
