import { date } from 'quasar';

/**
 * Convert Date time string format to readable format
 *
 * @param {string} dateStr Date time string format (YYYY-MM-DDTHH:mm:ss.sssZ)
 * @param {string} format format to display
 * @see {@link https://quasar.dev/quasar-utils/date-utils#format-for-display}
 * @returns {string} formated date
 * @example
 * formatDate('2023-04-24T07:13:59', 'DD.MM.YYYY') -> '24.04.2023'
 */
export default function formatDate(
  dateStr: string | number,
  format: string
): string {
  if (!date.isValid(dateStr)) {
    throw new Error('formatDate(): invalid Date time string format');
  }

  const timeStamp = new Date(dateStr);

  const formattedString = date.formatDate(timeStamp, format);

  if (!formattedString) {
    throw new Error('formatDate(): invalid format string');
  }

  return formattedString;
}
