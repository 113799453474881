<script setup lang="ts">
import IconChevronDown from '@/assets/images/icon-chevron-down.svg';
import CheckboxBase from '@/components/CheckboxBase.vue';
import RadioBase from '@/components/RadioBase.vue';

export interface ISelectOption {
  label: string;
  value: string | number;
  icon?: string;
}

type TModelValue = string | number | string[] | number[] | null;

const props = defineProps<{
  modelValue: TModelValue;
  options: ISelectOption[];
  label?: string;
  placeholder?: string;
  type?: 'checkbox' | 'radio';
  hasIcon?: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: TModelValue): void;
}>();

const selectHandler = function selectHandler(value: TModelValue) {
  emit('update:modelValue', value);
};

const isEmpty = computed<boolean>(
  () =>
    props.modelValue === null ||
    props.modelValue === '' ||
    (Array.isArray(props.modelValue) && props.modelValue.length === 0)
);

const radioValue = computed<string>(() => props.modelValue as string);

const optionIcon = computed<string>(() => {
  if (!props.hasIcon) return '';

  const icon = props.options.find((i) => i.value === props.modelValue)?.icon;

  return icon ?? '';
});
</script>

<template>
  <q-select
    class="select-base text-body"
    :class="{
      'select-base--labeled': props.label,
      'select-base--empty': isEmpty,
    }"
    popup-content-class="select-base-content"
    outlined
    hide-bottom-space
    emit-value
    map-options
    :menu-offset="[0, 4]"
    behavior="menu"
    transition-show="jump-down"
    transition-hide="jump-up"
    transition-duration="150"
    :dropdown-icon="`img:${IconChevronDown}`"
    :data-placeholder="props.placeholder"
    :label="props.label"
    :options="props.options"
    :model-value="props.modelValue"
    @update:model-value="selectHandler"
  >
    <template
      v-if="props.hasIcon && optionIcon"
      #prepend
    >
      <img
        :src="optionIcon"
        width="24"
        height="24"
      />
    </template>

    <template
      v-if="props.type === 'checkbox' || props.type === 'radio'"
      #option="{ itemProps, opt, selected, toggleOption }"
    >
      <q-item v-bind="itemProps">
        <q-item-section
          v-if="opt.icon"
          class="select-base__avatar"
          avatar
        >
          <img
            :src="opt.icon"
            :alt="opt.label"
            width="24"
            height="24"
          />
        </q-item-section>

        <q-item-section class="select-base__label">
          {{ opt.label }}
        </q-item-section>

        <q-item-section side>
          <CheckboxBase
            v-if="props.type === 'checkbox'"
            :model-value="selected"
            @update:model-value="toggleOption(opt)"
          />

          <RadioBase
            v-if="props.type === 'radio'"
            :value="opt.value"
            :model-value="radioValue"
            inline
            @update:model-value="toggleOption(opt)"
          />
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
.select-base {
  $this: 'select-base';

  --select-base-height: 48px;

  &__avatar {
    min-width: auto;
    padding-right: 8px;
  }

  &__label {
    color: initial;
  }

  .q-select__dropdown-icon {
    opacity: 0.6;
  }

  .q-field__prepend {
    z-index: 1;
  }

  .q-field__prepend + .q-field__control-container {
    .q-field__label {
      left: -50px;
    }
  }

  .q-field__control-container {
    padding-top: 0 !important;
  }

  .q-field__control {
    min-height: var(--select-base-height);
    border-radius: $border-radius-medium;
    background-color: $color-black-200;
    padding-left: 16px;
    padding-right: 16px;

    &::before {
      background-color: $color-black-100;
      border: 1.5px solid $color-black-300;
      transition: border-color $anim-fast, background-color $anim-fast;
      opacity: 1;
    }

    &::after {
      display: none;
    }

    &:hover {
      &::before {
        border-color: $color-black-300;
      }
    }
  }

  .q-field__native {
    min-height: auto;
    padding-top: 0 !important;
    padding-bottom: 0;
    text-overflow: ellipsis;
    color: $color-black-950;
    position: relative;

    &::before {
      content: attr(data-placeholder);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: $color-black-400;
      font-size: 15px;
      line-height: 23px;
      pointer-events: none;
      display: none;
    }
  }

  .q-field__marginal {
    height: auto;
  }

  .q-field__append {
    padding-left: 4px;

    .q-icon {
      width: 24px;
      height: 24px;
    }
  }

  .q-field__label {
    @include text-caption-13;

    color: $color-black-600;
    transform: none;
    transition: none;
    left: -15px;
    top: -26px;
  }

  .q-field__bottom {
    padding: 4px 0 0;
    min-height: auto;
  }

  .q-field__messages,
  .q-field__counter {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.04px;
    color: $color-black-500;
  }

  &--labeled {
    padding-top: 26px;
  }

  &--empty {
    .q-field__native::before {
      display: block;
    }
  }

  &.q-field--highlighted.q-field--outlined:not(.q-field--error),
  &.q-field--highlighted,
  &.q-field--focused {
    .q-field__control {
      &::before {
        border-color: $color-black-300;
      }
    }
  }

  &.q-field--filled {
    .q-field__control {
      &::before {
        background-color: $color-black-200;
      }
    }
  }

  &.q-field--error {
    .q-field__control {
      &::before {
        background-color: $color-warning-100;
        border-color: $color-warning-500;
      }

      &:hover {
        &::before {
          border-color: $color-warning-500;
        }
      }
    }

    .q-field__messages,
    .q-field__counter {
      color: $color-warning-500;
    }

    .q-field__control-container + .q-field__append:not(:last-of-type) {
      display: none;
    }
  }
}

.select-base-content {
  border: 1.5px solid $color-black-300;
  box-shadow: none;
  border-radius: $border-radius-medium;

  .q-item {
    padding: 12px 16px;
    min-height: 48px;

    .q-focus-helper {
      &::before {
        background-color: $color-black-200;
        transition: background-color $anim-primary, opacity $anim-primary;
        border-radius: $border-radius-medium;
      }

      &::after {
        display: none;
      }
    }
  }

  .q-item__label {
    line-height: inherit !important;
  }

  .q-item__section {
    position: relative;
  }
}

body .select-base-content .q-manual-focusable--focused > .q-focus-helper,
body .select-base-content .q-focusable:focus > .q-focus-helper,
body .select-base-content .q-hoverable:hover > .q-focus-helper {
  opacity: 1;
  background-color: transparent;
}

body
  .select-base-content
  .q-manual-focusable--focused
  > .q-focus-helper::before,
body .select-base-content .q-focusable:focus > .q-focus-helper::before,
body .select-base-content .q-hoverable:hover > .q-focus-helper::before {
  opacity: 1;
}
</style>
