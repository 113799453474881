<script setup lang="ts">
export interface IRadioOption {
  label: string;
  value: string | number;
}

const props = defineProps<{
  modelValue: string | number | null;
  options: IRadioOption[];
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string | number): void;
  (event: 'blur'): void;
}>();

const inputHandler = (value: string | number) => {
  emit('update:modelValue', value);
  emit('blur');
};
</script>

<template>
  <q-field
    class="radio-group-base"
    :model-value="props.modelValue"
    borderless
    stack-label
    no-error-icon
    hide-bottom-space
    lazy-rules
  >
    <template #control>
      <q-option-group
        :options="props.options"
        :model-value="props.modelValue"
        inline
        @update:model-value="inputHandler"
      />
    </template>
  </q-field>
</template>

<style lang="scss">
.radio-group-base {
  $this: 'radio-group-base';

  &.q-field--auto-height.q-field--labeled .q-field__control-container {
    padding-top: 26px;
  }

  .q-option-group--inline,
  .q-option-group--inline > div {
    margin-left: 0;
  }

  .q-radio {
    padding: 2px 16px 2px 4px;
    border-radius: $border-radius-small;
    transition: background-color $anim-primary;

    &:hover,
    &:focus-visible {
      background-color: $color-black-300;
    }

    &:active {
      background-color: $color-black-400;
    }

    &:not(.disabled) .q-radio__inner::before {
      display: none;
    }

    &__label {
      padding-top: 2px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.15px;
    }

    &__inner {
      color: $color-black-950;
      font-size: 44px;

      &--truthy {
        .q-radio__check {
          transform: scale3d(1.2, 1.2, 1);
        }
      }
    }
  }

  .q-field__native {
    padding-bottom: 0;
    color: $color-black-950;
  }

  .q-field__label {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.04px;
    color: $color-black-700;
    transform: none;
    transition: none;
    top: 0;
  }

  .q-field__bottom {
    padding: 4px 0 0;
    min-height: auto;
  }

  .q-field__messages {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.04px;
    color: $color-black-500;
  }

  &.q-field--error {
    .q-field__messages {
      color: $color-warning-500;
    }

    .q-radio__inner {
      color: $color-warning-500;
    }
  }
}
</style>
