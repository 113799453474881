<script setup lang="ts">
import type { QRadioProps } from 'quasar';

const props = defineProps<{
  modelValue: QRadioProps['modelValue'];
  value: string | number;
  inline?: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: QRadioProps['modelValue']): void;
}>();

const selectHandler = (value: QRadioProps['modelValue']) =>
  emit('update:modelValue', value);
</script>

<template>
  <q-radio
    class="radio-base"
    :class="{
      'radio-base--inline': props.inline,
    }"
    :model-value="props.modelValue"
    :val="props.value"
    @update:model-value="selectHandler"
  />
</template>

<style lang="scss">
.radio-base {
  $this: 'radio-base';

  border-radius: $border-radius-small;
  transition: background-color $anim-primary;

  &:hover,
  &:focus-visible {
    background-color: $color-black-300;
  }

  &:active {
    background-color: $color-black-400;
  }

  .q-radio__inner {
    color: $color-black-600;
    font-size: 44px;

    &--truthy {
      color: $color-secondary-500;

      .q-radio__check {
        transform: scale3d(1.2, 1.2, 1);
      }
    }
  }

  &--inline {
    &:hover,
    &:focus-visible,
    &:active {
      background-color: transparent;
    }

    .q-radio__bg {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .q-radio__inner {
      font-size: 18px;
    }
  }
}
</style>
