<script setup lang="ts">
export interface ITabOption {
  name: string | number;
  label: string;
  icon?: string;
}

const props = defineProps<{
  modelValue: string | number;
  options: ITabOption[];
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string | number | null): void;
}>();

const inputHandler = (value: string | number | null) =>
  emit('update:modelValue', value);
</script>

<template>
  <q-tabs
    class="tabs-roll"
    :model-value="props.modelValue"
    inline-label
    no-caps
    @update:model-value="inputHandler"
  >
    <q-tab
      v-for="option in props.options"
      :key="option.name"
      :ripple="false"
      :name="option.name"
      :label="option.label"
      :icon="option.icon"
    />
  </q-tabs>
</template>

<style lang="scss">
.tabs-roll {
  $this: 'tabs-roll';

  border-radius: $border-radius-medium;
  background-color: $color-black-200;

  .q-tabs__content {
    padding: 4px;
  }

  .q-tabs__arrow,
  .q-focus-helper {
    display: none;
  }

  .q-tab__label {
    @include text-body;

    color: $color-black-500;
    transition: color $anim-primary;
  }

  .q-tab {
    min-height: 40px;
    width: 100%;
    z-index: 1;

    &--active {
      z-index: 0;

      .q-tab__label {
        color: $color-black-800;
      }
    }
  }

  .q-tab__indicator {
    height: 100%;
    background-color: $color-black-100;
    border-radius: 6px;
    filter: $drop-shadow-small;
  }

  .q-tab__content {
    z-index: 1;
  }
}
</style>
