<script setup lang="ts">
import { CHIP_TYPE } from '@/types/base';
import { BOOK_TYPE } from '@/types/books';

import ChipBase from '@/components/ChipBase.vue';
import IconBase from '@/components/IconBase/IconBase.vue';
import TooltipBase from '@/components/TooltipBase.vue';

interface IBookStatus {
  type: CHIP_TYPE;
  label: string;
  icon: string;
}

const props = withDefaults(
  defineProps<{
    complited: boolean;
    onlyIcon?: boolean;
    showTooltip?: boolean;
    bordered?: boolean;
  }>(),
  {
    onlyIcon: false,
    showTooltip: false,
    bordered: false,
  }
);

const { t } = useI18n();

const typeMapper: Record<BOOK_TYPE, CHIP_TYPE> = {
  [BOOK_TYPE.completed]: CHIP_TYPE.grey,
  [BOOK_TYPE.ongoing]: CHIP_TYPE.green,
};

const labelMapper: Record<BOOK_TYPE, string> = {
  [BOOK_TYPE.completed]: t('book.type.completed'),
  [BOOK_TYPE.ongoing]: t('book.type.ongoing'),
};

const iconMapper: Record<BOOK_TYPE, string> = {
  [BOOK_TYPE.completed]: 'circle-check-16',
  [BOOK_TYPE.ongoing]: 'clock-16',
};

const badge = computed<IBookStatus>(() => {
  const type = props.complited ? BOOK_TYPE.completed : BOOK_TYPE.ongoing;

  return {
    type: typeMapper[type],
    label: labelMapper[type],
    icon: iconMapper[type],
  };
});
</script>

<template>
  <chip-base
    class="chip-book-type"
    :class="{
      'chip-book-type--icon': props.onlyIcon,
    }"
    :bordered="props.bordered"
    :type="badge.type"
  >
    <IconBase
      :name="badge.icon"
      size="sm"
    />

    <span
      v-if="!props.onlyIcon"
      class="q-ml-xs"
    >
      {{ badge.label }}
    </span>

    <tooltip-base
      v-if="props.showTooltip"
      dense
    >
      {{ badge.label }}
    </tooltip-base>
  </chip-base>
</template>

<style lang="scss" scoped>
.chip-book-type {
  $this: 'chip-book-type';

  &--icon {
    padding-left: 4px;
    padding-right: 4px;
  }
}
</style>
