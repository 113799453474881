<script setup lang="ts">
import type { VNode } from 'vue';

export interface IGroupOption {
  value: string | number;
  label: string;
  icon?: string;
  caption?: string;
  component?: VNode;
  disable?: boolean;
}

const props = withDefaults(
  defineProps<{
    modelValue: string | string[] | number | number[] | null;
    options: IGroupOption[];
    size?: 'normal' | 'large';
    grow?: boolean;
    type?: 'radio' | 'checkbox' | 'toggle';
  }>(),
  {
    size: 'normal',
    grow: false,
    type: 'radio',
  }
);

const emit = defineEmits<{
  (event: 'update:modelValue', value: string | number): void;
  (event: 'blur'): void;
}>();

const inputHandler = (value: string | number) => {
  emit('update:modelValue', value);
  emit('blur');
};
</script>

<template>
  <q-field
    class="option-group-card"
    :class="[
      `option-group-card--${props.size}`,
      { 'option-group-card--grow': props.grow },
    ]"
    :model-value="props.modelValue"
    borderless
    stack-label
    no-error-icon
    hide-bottom-space
    lazy-rules
  >
    <template #control>
      <q-option-group
        :options="props.options"
        :model-value="props.modelValue"
        left-label
        :type="props.type"
        @update:model-value="inputHandler"
      >
        <template #label="opt">
          <component
            :is="opt.component"
            v-if="opt.component"
          ></component>

          <div
            v-else
            class="option-group-card__header text-body text-weight-medium flex no-wrap items-center"
          >
            <img
              v-if="opt.icon"
              :src="opt.icon"
              :alt="opt.label"
              width="24"
              height="24"
              class="q-mr-sm"
            />
            {{ opt.label }}
          </div>

          <div
            v-if="opt.caption"
            class="option-group-card__caption text-body"
          >
            {{ opt.caption }}
          </div>
        </template>
      </q-option-group>
    </template>
  </q-field>
</template>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
.option-group-card {
  $this: 'option-group-card';

  & > .q-field__inner > .q-field__control {
    min-height: auto;
  }

  &__caption {
    padding-top: 16px;
    color: $color-black-700;
    margin-right: -44px;

    @include media('sm') {
      padding-top: 20px;
      margin-right: -48px;
    }
  }

  .q-field__bottom {
    padding: 4px 0 0;
    min-height: auto;
  }

  .q-field__messages,
  .q-field__counter {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.04px;
    color: $color-black-500;
  }

  .q-field__native {
    padding: 0;
    min-height: auto;
    display: block;
  }

  .q-option-group {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
  }

  .q-option-group > div {
    margin: 12px;
  }

  .q-radio__inner {
    color: $color-black-300;
    font-size: 20px;
    margin-left: 24px;
    margin-top: 2px;
    margin-bottom: 2px;

    &--truthy {
      .q-radio__check {
        transform: scale3d(1.2, 1.2, 1);
      }
    }
  }

  .q-radio__bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .q-radio__label {
    flex-grow: 1;
  }

  .q-radio {
    align-items: flex-start;
    height: 100%;
    width: 100%;
    line-height: 24px;
    min-height: 48px;
    padding: 10px 16px;
    border-radius: $border-radius-medium;
    border: $border-primary;
    transition: box-shadow $anim-primary;

    @include media-hover {
      &:not(.disabled):hover,
      &:not(.disabled):focus-visible {
        box-shadow: $shadow-medium-dark;
      }
    }

    &:not(.disabled) .q-radio__inner::before {
      display: none;
    }

    &[aria-checked='true'] {
      border-color: $color-secondary-500;

      .q-radio__inner {
        color: $color-secondary-500;
      }
    }

    @include media('sm') {
      padding: 10px 24px;
    }
  }

  .q-checkbox__inner {
    color: $color-black-300;
    font-size: 18px;
    margin-left: 12px;
    margin-top: 3px;
    margin-bottom: 3px;

    @include media('sm') {
      font-size: 20px;
      margin-left: 24px;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    &--truthy {
      .q-checkbox__check {
        transform: scale3d(1.2, 1.2, 1);
      }
    }
  }

  .q-checkbox__bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 1.5px;
  }

  .q-checkbox__label {
    flex-grow: 1;
  }

  .q-checkbox {
    align-items: flex-start;
    height: 100%;
    width: 100%;
    line-height: 24px;
    min-height: 48px;
    padding: 10px 16px;
    border-radius: $border-radius-medium;
    border: $border-primary;
    transition: box-shadow $anim-primary;

    @include media-hover {
      &:not(.disabled):hover,
      &:not(.disabled):focus-visible {
        box-shadow: $shadow-medium-dark;
      }
    }

    &:not(.disabled) .q-checkbox__inner::before {
      display: none;
    }

    &[aria-checked='true'] {
      border-color: $color-secondary-500;

      .q-checkbox__inner {
        color: $color-secondary-500;
      }

      .q-checkbox__bg {
        background: transparent;
      }

      .q-checkbox__svg {
        color: $color-secondary-500;
        transform: scale(0.8);
      }
    }

    @include media('sm') {
      padding: 10px 24px;
    }
  }

  &--large {
    .q-radio,
    .q-checkbox {
      padding: 16px;

      @include media('sm') {
        padding: 24px;
      }
    }
  }

  &--grow {
    .q-option-group {
      flex-wrap: nowrap;
    }

    .q-option-group > div {
      width: 100%;
    }
  }

  &.q-field--error {
    .q-radio,
    .q-checkbox {
      border-color: $color-warning-500;
      background-color: $color-warning-100;
    }

    // .q-radio__inner,
    // .q-checkbox__inner {
    //   color: $color-warning-500;
    // }

    .q-field__messages,
    .q-field__counter {
      color: $color-warning-500;
    }
  }
}
</style>
