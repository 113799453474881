<script setup lang="ts">
export interface IRadioOption {
  label: string;
  value: string | number;
  icon?: string;
}

const props = defineProps<{
  modelValue: string | number | null;
  options: IRadioOption[];
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const inputHandler = (value: string) => emit('update:modelValue', value);
</script>

<template>
  <q-field
    class="radio-group-block"
    :model-value="props.modelValue"
    lazy-rules
    outlined
    no-error-icon
    hide-bottom-space
  >
    <template #control>
      <q-option-group
        left-label
        :options="props.options"
        :model-value="props.modelValue"
        @update:model-value="inputHandler"
      >
        <template #label="opt">
          <img
            v-if="opt.icon"
            :src="opt.icon"
            :alt="opt.label"
            width="24"
            height="24"
            class="q-mr-sm"
          />
          {{ opt.label }}
        </template>
      </q-option-group>
    </template>
  </q-field>
</template>

<style lang="scss">
.radio-group-block {
  $this: 'radio-group-block';

  & > .q-field__inner {
    padding: 0;

    & > .q-field__control {
      padding: 0;
      min-height: auto;

      &::before,
      &::after {
        display: none;
      }

      & > .q-field__control-container {
        & > .q-field__native {
          padding: 0 !important;
        }
      }
    }
  }

  .q-option-group {
    margin-left: 0;
    width: 100%;

    & > div {
      margin-left: 0;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .q-radio {
    width: 100%;
    padding: 4px 4px 4px 16px;
    border: 1.5px solid $color-black-300;
    border-radius: $border-radius-medium;
    transition: background-color $anim-primary;

    &:not(.disabled) .q-radio__inner::before {
      display: none;
    }

    &:hover,
    &:focus-visible {
      background-color: $color-black-200;
    }

    &:active {
      background-color: $color-black-300;
    }

    &__label {
      @extend %text-body;

      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &__inner {
      color: $color-black-600;
      font-size: 36px;

      &--truthy {
        color: $color-secondary-500;

        .q-radio__check {
          transform: scale3d(1.2, 1.2, 1);
        }
      }
    }
  }

  .base-icon {
    margin-right: 12px;
  }

  .q-field__bottom {
    padding: 4px 0 0;
  }

  .q-field__messages {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.06px;
  }

  &.q-field--error {
    .q-field__messages {
      color: $color-warning-500;
    }

    .q-radio {
      border-color: $color-warning-500;
    }
  }
}
</style>
