import { isApiError, formatErrorsMsg } from '@/api';
import { useUserStoreWithOut } from '@/stores/user';

const userStore = useUserStoreWithOut();

interface IOptions {
  location: string;
  [key: string]: string | number | boolean;
}

export default class ErrorService {
  static onError(error: unknown, options?: IOptions) {
    ErrorService.newrelicLogEngine(error, options);
  }

  static newrelicLogEngine(
    error: unknown,
    options: IOptions = { location: '' }
  ) {
    const newrelicAttrs = { ...options };

    if (userStore.$state.userData?.email) {
      newrelicAttrs.userEmail = userStore.$state.userData.email;
    }

    if (isApiError(error)) {
      newrelicAttrs.apiErrorCode = error.code ?? 0;

      if (error.errors) {
        newrelicAttrs.apiErrorMessage = formatErrorsMsg(error.errors);
      }
    } else if (error instanceof Error) {
      newrelicAttrs.customMessage = error.message;
    }

    try {
      window.newrelic?.noticeError(error as Error, newrelicAttrs);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}
