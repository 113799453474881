import type { App as Application } from 'vue';
import type { Router } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import { useUserStoreWithOut } from '@/stores/user';
import { setHttpConnectors } from '@/api';

import initBaseRoutes from './base';
import initAuthentificationRoutes from './authentification';
import initDashboardRoutes from './dashboard';

declare module 'vue-router' {
  interface RouteMeta {
    i18nAlias: string;
    platform?: string;
    needAuth?: boolean;
  }
}

export default async function setupRouter(app: Application): Promise<Router> {
  const userStore = useUserStoreWithOut();

  const routes = [
    ...initAuthentificationRoutes(userStore),
    ...initDashboardRoutes(userStore),
    ...initBaseRoutes(),
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/layouts/LayoutDefault.vue'),
      children: [
        {
          path: '',
          name: 'NotFound',
          component: () => import('@/pages/not-found/PageNotFound.vue'),
          meta: {
            i18nAlias: 'not-found',
            needAuth: true,
          },
        },
      ],
    },
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
      if (to.meta.preventScrollToTop) {
        return {};
      }

      // always scroll to top
      return { top: 0 };
    },
  });

  setHttpConnectors([
    async () => {
      userStore.cleanUserData();

      router.push({
        name: 'Login',
      });
    },
  ]);

  try {
    await userStore.getUserData();
  } catch (_error) {
    //
  }

  router.beforeEach((to) => {
    if (to.meta.needAuth && !userStore.isUserLogin) {
      return { name: 'Login' };
    }
    return true;
  });

  app.use(router);

  await router.isReady();

  return router;
}
