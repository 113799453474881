import type { RouteRecordRaw } from 'vue-router';

export default function initBaseRoutes() {
  const routes: RouteRecordRaw[] = [
    {
      path: '/',
      redirect: { name: 'Statistic' },
    },
  ];

  return routes;
}
