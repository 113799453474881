import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'income',
    component: () => import('@/layouts/LayoutDefault.vue'),
    children: [
      {
        path: '',
        name: 'Income',
        component: () => import('@/pages/income/PageIncome.vue'),
        meta: {
          i18nAlias: 'income',
          needAuth: true,
        },
      },
    ],
  },
];

export default routes;
