<script setup lang="ts">
export interface ITabOption {
  name: string | number;
  label: string;
  icon?: string;
}

const props = defineProps<{
  modelValue: string | number;
  options: ITabOption[];
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string | number | null): void;
}>();

const inputHandler = (value: string | number | null) =>
  emit('update:modelValue', value);
</script>

<template>
  <q-tabs
    class="tabs-base"
    :model-value="props.modelValue"
    inline-label
    no-caps
    @update:model-value="inputHandler"
  >
    <q-tab
      v-for="option in props.options"
      :key="option.name"
      class="tabs-base__item"
      :ripple="false"
      :name="option.name"
      :label="option.label"
      :icon="option.icon"
    />
  </q-tabs>
</template>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
.tabs-base {
  $this: 'tabs-base';

  &__item {
    margin-left: 8px;
    margin-right: 8px;
    min-height: 34px;
    padding: 0;
    flex: none !important;

    &.q-tab--active {
      .q-focus-helper {
        opacity: 0 !important;
      }

      .q-tab__label {
        color: $color-black-950;
      }
    }

    &:hover {
      .q-tab__label {
        color: $color-black-950;
      }
    }

    .q-tab__content {
      padding: 0;
      min-width: auto;
    }

    .q-tab__label {
      @extend %text-body;

      color: $color-black-600;
      transition: color $anim-primary;
    }

    .q-focus-helper {
      &::after {
        display: none;
      }
    }
  }

  .q-tabs__arrow {
    display: none;
  }

  .q-tabs__content {
    overflow: visible;
    justify-content: flex-start;
  }
}

body {
  .tabs-base__item.q-manual-focusable--focused > .q-focus-helper,
  .tabs-base__item.q-focusable:focus > .q-focus-helper,
  .tabs-base__item.q-hoverable:hover > .q-focus-helper {
    opacity: 1;
    background-color: transparent;
    left: -8px;
    border-radius: $border-radius-medium;

    @media (hover: hover) and (pointer: fine) {
      width: calc(100% + 16px);
    }
  }

  .tabs-base__item.q-manual-focusable--focused > .q-focus-helper::before,
  .tabs-base__item.q-focusable:focus > .q-focus-helper::before,
  .tabs-base__item.q-hoverable:hover > .q-focus-helper::before {
    opacity: 1;
  }

  /* base */
  .tabs-base__item .q-focus-helper::before {
    background-color: $color-black-100;
  }

  // .tabs-base__item.q-manual-focusable--focused > .q-focus-helper::before,
  // .tabs-base__item.q-focusable:focus > .q-focus-helper::before {
  //   background-color: $color-black-300;
  // }
}
</style>
