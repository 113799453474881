import type { App } from 'vue';
import { createPinia } from 'pinia';

/**
 * Pinia store instance.
 * Need to be used outside the setup to init store modules
 *
 * @see {@link https://pinia.vuejs.org/core-concepts/outside-component-usage.html}
 */
export const store = createPinia();

/**
 * Register Pinia store
 *
 * @see {@link https://pinia.vuejs.org/}
 * @param {App} app vue app, result of vue.createApp
 */
export function setupStore(app: App) {
  app.use(store);
}
