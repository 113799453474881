import type { Router } from 'vue-router';

interface IPwaSetup {
  router: Router;
}

export const canInstallPwa = ref<boolean>(false);

let deferredPrompt: BeforeInstallPromptEvent | null;

window.addEventListener('beforeinstallprompt', (e) => {
  deferredPrompt = e as BeforeInstallPromptEvent;

  canInstallPwa.value = true;
});

export async function showInAppInstallPromotion(
  acceptedCb?: () => void,
  dismissedCb?: () => void
) {
  if (!deferredPrompt) return;

  deferredPrompt.prompt();

  const { outcome } = await deferredPrompt.userChoice;

  deferredPrompt = null;

  if (outcome === 'accepted') {
    acceptedCb?.();
  } else if (outcome === 'dismissed') {
    dismissedCb?.();
  }
}

export async function setPwaModule({ router }: IPwaSetup) {
  await router.isReady();

  // eslint-disable-next-line import/no-unresolved
  const { registerSW } = await import('virtual:pwa-register');

  registerSW({
    immediate: true,
  });
}
