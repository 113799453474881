import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'profile',
    component: () => import('@/layouts/LayoutDefault.vue'),
    children: [
      {
        path: '',
        name: 'Profile',
        component: () => import('@/pages/profile/PageProfile.vue'),
        meta: {
          i18nAlias: 'profile',
          needAuth: true,
        },
      },
    ],
  },
];

export default routes;
