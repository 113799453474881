<script setup lang="ts">
import { CHIP_TYPE } from '@/types/base';
import { urlValidation } from '@/utils';

import InputBase from '@/components/InputBase/InputBase.vue';
import ChipBase from '@/components/ChipBase.vue';

interface IChipLink {
  label: string;
  link: string;
}

const props = withDefaults(
  defineProps<{
    modelValue: string[] | null;
    label?: string;
    placeholder?: string;
    disable?: boolean;
  }>(),
  {
    label: '',
    placeholder: '',
    disable: false,
  }
);

const emit = defineEmits<{
  (event: 'update:modelValue', value: string[]): void;
}>();

const { t } = useI18n();

const inputValue = ref<string>('');

const inputRules: TValidationRule[] = [
  (val: string) => !!val || t('form.error.blank'),
  (val: string) => urlValidation(val) || t('form.error.url'),
];

const isValid = ref<boolean>(true);
const errorMessage = ref<string>('');

const validate = function validateInput() {
  const statuses = inputRules.map((rule) => rule(inputValue.value));

  isValid.value = statuses.every((status) => typeof status === 'boolean');

  errorMessage.value = isValid.value
    ? ''
    : (statuses.filter((status) => typeof status === 'string') as string[])[0];
};

const resetValidation = function resetValidation() {
  isValid.value = true;
  errorMessage.value = '';
};

const inputHandler = function inputHandler(value: string | number | null) {
  resetValidation();

  if (value === null) {
    inputValue.value = '';
  } else {
    inputValue.value = value.toString();
  }
};

const chips = computed<IChipLink[]>(() => {
  if (!props.modelValue) return [];

  return props.modelValue.reduce((acc, link) => {
    if (!urlValidation(link)) {
      return acc;
    }

    acc.push({
      link,
      label: new URL(link).hostname,
    });

    return acc;
  }, [] as IChipLink[]);
});

const hasProtocol = function isUrlHasProtocol(string: string): boolean {
  const pattern = /^https?:\/\/?/i;

  return pattern.test(string);
};

const addChip = function addChip() {
  validate();

  if (!isValid.value) return;

  const locLink = hasProtocol(inputValue.value)
    ? inputValue.value
    : `https://${inputValue.value}`;

  const newList = [...new Set([...(props.modelValue ?? []), locLink])];

  emit('update:modelValue', newList);

  inputHandler('');
  resetValidation();
};

const removeChip = function removeChip(link: string) {
  if (!props.modelValue) return;

  const newList = props.modelValue.filter((item) => item !== link);

  emit('update:modelValue', newList);
};
</script>

<template>
  <input-base
    class="input-chip"
    :model-value="inputValue"
    :label="props.label"
    :placeholder="props.placeholder"
    :error="!isValid"
    :error-message="errorMessage"
    :disable="props.disable"
    @update:model-value="inputHandler"
    @keydown.enter.prevent="addChip"
    @keydown.space.prevent="addChip"
    @blur="resetValidation"
  >
    <template #prepend>
      <div
        v-if="chips.length !== 0"
        class="input-chip__list"
        @click.stop
      >
        <chip-base
          v-for="chip in chips"
          :key="chip.link"
          removable
          :type="CHIP_TYPE.light_violet"
          class="input-chip__chip"
          @remove="removeChip(chip.link)"
        >
          <a
            class="input-chip__link"
            :href="chip.link"
            target="_blank"
          >
            {{ chip.label }}
          </a>
        </chip-base>
      </div>
    </template>
  </input-base>
</template>

<style lang="scss">
.input-chip {
  $this: 'input-chip';

  &.q-field--disabled {
    .#{$this}__list {
      pointer-events: none;
    }
  }

  .q-field__control {
    padding-top: 12px;
    padding-bottom: 12px;
    flex-direction: column;
  }

  .q-field__prepend {
    padding-right: 0;
    pointer-events: none;
  }

  .q-field__control-container {
    min-height: 24px;
  }

  &__list {
    pointer-events: all;
    padding-top: 4px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__link {
    text-decoration: none;
    color: inherit;
  }
}
</style>
