import { accessToken, refreshToken } from '@/helpers/tokens';

export interface IApiErrorMsg {
  name: string;
  messages: string | string[];
}

export interface IApiData<T = unknown> {
  success: boolean;
  data: T;
  status?: string;
  code?: number;
  errors?: IApiErrorMsg[];
}

interface IFetchOptions extends RequestInit {
  url: string;
  abortController?: AbortController;
}

export interface IRefreshTokenResponse {
  access_token: string;
  expired_at: string;
  refresh_expired_at: string;
  refresh_token: string;
  token_type: string;
}

type Connector = () => Promise<void>;

const REFRESH_TOKEN_URL = `${import.meta.env.VITE_API_URL}/auth/refresh`;

const httpConnectors: Connector[] = [];

/**
 * Set functions that need to be called when cannot refresh access tokens
 *
 * @param {Connector[]} connectors - array of async functions
 * @example
 * setHttpConnectors([async () => {}])
 */
export function setHttpConnectors(connectors: Connector[]) {
  connectors.forEach((connector) => httpConnectors.push(connector));
}

/**
 * Check if occurred error is type of IApiData
 *
 * @param {unknown} errorData - any sort of data
 * @returns {boolean} error is type of IApiData `true/false`
 * @example
 * isApiError(Error) -> true/false
 */
export function isApiError(errorData: unknown): errorData is IApiData {
  return (
    typeof errorData === 'object' && errorData !== null && 'errors' in errorData
  );
}

/**
 * Format api errors to one string
 *
 * @param {IApiErrorMsg[]} errors - array of api errors
 * @returns {string} combined error messages
 * @example
 * formatErrorsMsg([Error, Error]) -> 'Msg. Msg'
 */
export function formatErrorsMsg(errors: IApiErrorMsg[]): string {
  return errors.flatMap((e) => e.messages).join(' ');
}

/**
 * Fetch data from server. Api call.
 * If 401 error occurres, try to auto refresh access token.
 * If cannot refresh token, calls httpConnectors callback functions.
 *
 * @param {IFetchOptions} options - fetch options (based on Fetch Api)
 * @returns {Promise<IApiData<T>>} fetching data or error
 * @example
 * http<ILoginResponse>({
    method: 'POST',
    url: '/auth/login/',
    body: JSON.stringify(payload),
   })
 */
export async function http<T>(options: IFetchOptions): Promise<IApiData<T>> {
  const headers: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(options.headers as Record<string, string>),
  };

  if (headers['Content-Type'] === 'multipart/form-data') {
    delete headers['Content-Type'];
  }

  const fetchOptions: RequestInit = {
    headers,
    method: options.method,
  };

  // request interceptor starts
  const token = accessToken.get();

  if (token) {
    (
      fetchOptions.headers as Record<string, string>
    ).Authorization = `Bearer ${token}`;
  }
  // request interceptor ends

  if (options.body) {
    fetchOptions.body = options.body;
  }

  const url = import.meta.env.VITE_API_URL + options.url;

  const ABORT_FETCH_TIME = 30000;
  const controller = options.abortController ?? new AbortController();
  const timerId = setTimeout(() => controller.abort(), ABORT_FETCH_TIME);

  const response = await fetch(url, {
    ...fetchOptions,
    signal: controller.signal,
  });

  clearTimeout(timerId);

  // response interceptor starts
  if (!response.ok) {
    const proccessErrorData = async (res: Response) => {
      const errorsData = await res.json();
      errorsData.statusCode = res.status;
      // not auth error handling
      return Promise.reject(errorsData);
    };

    if (response.status !== 401) {
      return proccessErrorData(response);
    }

    const proccessRefreshErrorData = async (res: Response) => {
      // can't refresh token
      // eslint-disable-next-line no-restricted-syntax
      for await (const connector of httpConnectors) {
        connector();
      }

      refreshToken.remove();

      const errorsData = await res.json();

      return Promise.reject(errorsData);
    };

    accessToken.remove();

    const refToken = refreshToken.get();

    if (!refToken) {
      return proccessRefreshErrorData(response);
    }

    try {
      const refreshResponse = await fetch(REFRESH_TOKEN_URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refToken}`,
        },
      });

      if (!refreshResponse.ok) {
        return proccessRefreshErrorData(response);
      }

      const refreshResponseData: IApiData<IRefreshTokenResponse> =
        await refreshResponse.json();

      accessToken.set(refreshResponseData.data.access_token);
      refreshToken.set(refreshResponseData.data.refresh_token);

      return http<T>(options);
    } catch (error) {
      return proccessRefreshErrorData(response);
    }
  }
  // response interceptor ends

  return response.json();
}
