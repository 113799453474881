import { createApp } from 'vue';

import { setupStore } from '@/stores';
import setupRouter from '@/router';
import setUiFramework from '@/plugins/quasar';
import registerComponents from '@/plugins/globalComponents';
import { setPwaModule } from '@/plugins/pwa';
import ErrorService from '@/plugins/errorService';
import { setupI18n } from '@/locales/i18n';

// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register';

import '@/assets/scss/index.scss';

import MainApp from './App.vue';

(async () => {
  const app = createApp(MainApp);

  setupStore(app);

  const router = await setupRouter(app);

  await setupI18n({
    router,
    appInstance: app,
    pageName: router.currentRoute.value.meta.i18nAlias,
  });

  setUiFramework(app);
  registerComponents(app);

  setPwaModule({ router });

  app.config.errorHandler = (err, instance) => {
    ErrorService.onError(err, {
      // eslint-disable-next-line no-underscore-dangle
      location: `app.config.errorHandler: ${instance?.$options.__name ?? ''}`,
      componentProps: JSON.stringify(instance?.$props),
    });
  };

  app.mount('#app');
})();
