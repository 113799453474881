import { defineStore } from 'pinia';
import { store } from '@/stores';

import type { ILanguage } from '@/locales/languages';
import { languages as availableLanguages, LOCALES } from '@/locales/languages';

interface IBaseStore {
  languages: ILanguage[];
  activeLangAlias: LOCALES;
  isMenuOpened: boolean;
  isAuthorInfoModalInit: boolean;
  isAuthorInfoModalOpened: boolean;
  authorInfoModalCallback: () => Promise<void>;
}

export const useBaseStore = defineStore('base', {
  state(): IBaseStore {
    return {
      languages: availableLanguages,
      activeLangAlias: LOCALES.EN,
      isMenuOpened: true,
      isAuthorInfoModalInit: false,
      isAuthorInfoModalOpened: true,
      authorInfoModalCallback: () => Promise.resolve(),
    };
  },

  actions: {
    toggleMenu() {
      this.isMenuOpened = !this.isMenuOpened;
    },

    showAuthorInfoModal(cb?: () => Promise<void>) {
      if (cb) {
        this.authorInfoModalCallback = cb;
      }

      this.isAuthorInfoModalInit = true;
      this.isAuthorInfoModalOpened = true;
    },

    closeAuthorInfoModal() {
      this.authorInfoModalCallback = () => Promise.resolve();
      this.isAuthorInfoModalOpened = false;
    },
  },

  getters: {
    activeLang(): ILanguage | undefined {
      return this.languages.find((lang) => lang.value === this.activeLangAlias);
    },
  },
});

// Need to be used outside the setup
export function useBaseStoreWithOut() {
  return useBaseStore(store);
}
