export interface IChartPoint {
  date: string;
  value: number;
}

export interface IChartPointFormated extends IChartPoint {
  //timestamp: number;
}

export enum CHART_PERIOD {
  week = 'week',
  month = 'month',
  allTime = 'all-time'
}

export enum GENDER_TYPE {
  male = 1,
  female = 2,
  nonbinary = 3,
}

export interface IGenders {
  id: GENDER_TYPE;
  name: string;
  icon?: string;
}

export enum CHIP_TYPE {
  violet = 'violet',
  grey = 'grey',
  green = 'green',
  red = 'red',
  orange = 'orange',
  blue = 'blue',
  white = 'white',
  light_violet = 'light_violet',
}

export interface IBadgeIncrease {
  value: number;
  type: CHIP_TYPE;
}

export interface IOptionBase {
  label: string;
  value: string | number;
  icon?: string;
}

export interface ICountry {
  label: string;
  value: string;
}

export interface IDonutChartPoint {
  value: number;
  label: string;
  color: string;
}

export interface IMapChartPoint {
  value: string;
  countryId: string;
  color: string;
}
