import type { App } from 'vue';
import { Quasar, Cookies, Notify, Loading, QSpinnerTail } from 'quasar';
import type { QuasarUIConfiguration } from 'quasar';

import '@quasar/extras/material-icons/material-icons.css';
import 'quasar/src/css/index.sass';

/**
 * Register Quasar UI Framework
 *
 * @see {@link https://quasar.dev/start/vite-plugin}
 * @param {App} app vue app, result of vue.createApp
 */
export default function setUiFramework(app: App) {
  app.use(Quasar, {
    plugins: {
      Cookies,
      Notify,
      Loading,
    },
    config: {
      notify: {
        closeBtn: true,
        position: 'top',
        classes: 'notify-base',
        group: false,
      },
      loading: {
        spinnerColor: 'primary',
        spinner: QSpinnerTail,
        spinnerSize: 28,
        messageColor: 'white',
        customClass: 'loading-base',
      },
      framework: {
        cssAddon: true,
      },
    } as QuasarUIConfiguration,
  });
}
