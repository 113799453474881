<script setup lang="ts">
import type { QBannerSlots } from 'quasar';

type BannerType = 'base' | 'success' | 'warning' | 'error';

interface IProps {
  type?: BannerType;
}

const props = withDefaults(defineProps<IProps>(), {
  type: 'base',
});

const slots = Object.keys(useSlots()) as [keyof QBannerSlots];
</script>

<template>
  <q-banner
    class="banner-base"
    :class="[`banner-base--${props.type}`]"
    rounded
    inline-actions
  >
    <template
      v-for="name in slots"
      :key="name"
      #[name]
    >
      <slot :name="name"></slot>
    </template>
  </q-banner>
</template>

<style lang="scss" scoped>
.banner-base {
  $this: 'banner-base';

  border-width: 1.5px;
  border-style: solid;
  padding: 16px 16px 16px 24px;
  align-items: flex-start;

  &--base {
    background-color: $color-black-100;
    border-color: $color-black-500;
  }

  &--success {
    background-color: $color-success-100;
    border-color: $color-success-500;
  }

  &--warning {
    background-color: $color-warning-100;
    border-color: $color-warning-500;
  }

  &--error {
    background-color: $color-error-100;
    border-color: $color-error-500;
  }

  :deep(.q-banner__content) {
    @include text-body;
  }
}
</style>
