import type { ApiUserResponse } from '@/types/user';

import { http } from './index';

// --------------------------------------------------------

// eslint-disable-next-line import/prefer-default-export
export async function apiGetUserData() {
  return http<ApiUserResponse>({
    method: 'GET',
    url: '/user/',
  });
}
