import type { RouteRecordRaw } from 'vue-router';
import novelsRoutes from './novels';
import statisticRoutes from './statistic';
import profileRoutes from './profile';
import incomeRoutes from './income';

interface IStore {
  isUserLogin: boolean;
  isUserVerified: boolean;
}

export default function initDashboardRoutes(store: IStore) {
  const routes: RouteRecordRaw[] = [
    {
      path: '/dashboard',
      redirect: {
        name: 'Statistic',
      },
      beforeEnter() {
        if (store.isUserLogin && store.isUserVerified) {
          return true;
        }

        return {
          name: 'Login',
        };
      },
      children: [
        ...statisticRoutes,
        ...profileRoutes,
        ...incomeRoutes,
        ...novelsRoutes,
      ],
    },
  ];

  return routes;
}
