<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean | null;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
}>();

const selectHandler = (value: boolean) => emit('update:modelValue', value);
</script>

<template>
  <q-checkbox
    class="checkbox-base"
    :model-value="props.modelValue"
    @update:model-value="selectHandler"
  />
</template>

<style lang="scss">
.checkbox-base {
  $this: 'checkbox-base';

  .q-checkbox__inner {
    color: $color-black-600;
    width: 18px;
    height: 18px;
    font-size: unset;

    &::before {
      display: none;
    }

    &--truthy {
      color: $color-secondary-500;
    }
  }

  .q-checkbox__bg {
    border-width: 1.5px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .q-checkbox__truthy {
    stroke: $color-secondary-500;
  }

  .q-checkbox__inner--truthy .q-checkbox__bg,
  .q-checkbox__inner--indet .q-checkbox__bg {
    background: transparent;
  }

  .q-checkbox__svg {
    transform: scale(0.8);
  }
}
</style>
